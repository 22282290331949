<script>
import formatingData from '../mixins/formatingData'
import STACExplorerFilters from './STACExplorerFilters.vue'
import STACExplorerExactCount from './STACExplorerExactCount.vue'
import STACExplorerFetcher from './STACExplorerFetcher.vue'
import STACExplorerAssets from './STACExplorerAssets.vue'

import STAC_COLLECTION_QUERY from '../graphql/query/STACCollection.gql'
import STAC_COLLECTION_SUBSCRIPTION from '../graphql/subscription/STACCollection.gql'
import COUNT_STAC_ITEMS_QUERY from '../graphql/query/STACItemsCount.gql'

export default {
  name: 'STACExplorer',
  components: {
    STACExplorerFilters,
    STACExplorerExactCount,
    STACExplorerFetcher,
    STACExplorerAssets
  },
  mixins: [formatingData],
  props: {
    value: {
      type: Array,
      required: true
    },
    collection: {
      type: String,
      required: true
    },
    criterias: {
      type: Object,
      default: () => ({})
    },
    geometry: {
      type: Object
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    selectedFeature: { // For single select
      type: Object,
    },
    selectedFeatures: { // For multiselect
      type: Array,
      default: () => []
    },
    orderBy: {
      type: String,
      default: '-properties.datetime'
    },
    targetCollection: String,
    totalCount: {
      type: Number,
      default: 0
    },
    ingestLoading: {
      type: Boolean,
      default: false
    },
    advancedOrderBy: {
      type: Boolean,
      default: false
    },
    virtualScroll: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    // Requires multiselect == false, select first item available and prevent item deselect
    mandatory: {
      type: Boolean,
      default: false
    },
    hideExactCount: {
      type: Boolean,
      default: false
    },
    showHiddenCount: {
      type: Boolean,
      default: false
    },
    showCollectionAssets: {
      type: Boolean,
      default: true
    },
    wrapFilters: {
      type: Boolean,
      default: false
    },
    disableIdImport: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    STACCollection: {
      query: STAC_COLLECTION_QUERY,
      variables() {
        return {
          id: this.collection
        }
      },
      result({ data }) {
        this.internalIngestLoading =  data?.STACCollection?.properties?.ingestLoading || false
      },
      update(data) {
        this.internalIngestLoading =  data?.STACCollection?.properties?.ingestLoading || false
        return data.STACCollection
      },
      subscribeToMore: {
        document: STAC_COLLECTION_SUBSCRIPTION,
        variables() {
          return {
            types: ['UPDATED'],
            criterias: {
              id: this.collection
            }
          }
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          if (subscriptionData?.data?.STACCollection) {
            const { entity } = subscriptionData.data.STACCollection
            const STACCollection = {
              ...previousResult.STACCollection,
              ...entity
            }
            return { STACCollection }
          }
        }
      }
    },
    // FIXME: add count to collection from graphql backend
    collectionTotalCount: {
      query: COUNT_STAC_ITEMS_QUERY,
      variables() {
        return {
          criterias: {
            collection: this.collection
          }
        }
      },
      update(data) {
        return data.countSTACItem
      },
      skip() {
        return !this.showHiddenCount
      }
    },
    collectionFilteredCount: {
      query: COUNT_STAC_ITEMS_QUERY,
      variables() {
        return {
          criterias: {
            collection: this.collection,
            ...this.criterias
          }
        }
      },
      update(data) {
        return data.countSTACItem
      },
      skip() {
        return !this.showHiddenCount
      }
    }
  },
  data() {
    return {
      queryLoading: false,
      exactCount: false,
      STACCollection: {}
    }
  },
  computed: {
    hiddenCount() {
      return this.collectionTotalCount - this.collectionFilteredCount
    },
    internalIngestLoading: {
      get() {
        return this.ingestLoading
      },
      set(v) {
        this.$emit('update:ingestLoading', v)
      }
    },
    internalTotalCount: {
      get() {
        return this.totalCount
      },
      set(v) {
        this.$emit('update:totalCount', v)
      }
    },
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    internalCriterias: {
      get() {
        return this.criterias
      },
      set(v) {
        this.$emit('update:criterias', v)
      }
    },
    internalSelectAll: {
      get() {
        return this.selectAll
      },
      set(v) {
        this.$emit('update:selectAll', v)
      }
    },
    internalWrapFilters: {
      get() {
        return this.wrapFilters
      },
      set(v) {
        this.$emit('update:wrapFilters', v)
      }
    },
    internalOrderBy: {
      get() {
        return this.orderBy
      },
      set(v) {
        this.$emit('update:orderBy', v)
      }
    },
    internalSelectedFeatures: {
      get() {
        return this.selectedFeatures
      },
      set(v) {
        this.$emit('update:selectedFeatures', v)
      }
    },
    internalSelectedFeature: {
      get() {
        return this.selectedFeature
      },
      set(v) {
        this.$emit('update:selectedFeature', v)
      }
    },
    imageCount() {
      return this.selectAll ? this.internalTotalCount : this.selectedFeatures.length
    },
    enableCollectionAssets() {
      return this.showCollectionAssets && this.collection.assets?.length
    }
  },
  watch: {
    collection(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetSelection()
      }
    }
  },
  methods: {
    refresh () {
      this.$apollo.queries.STACCollection.refresh()
      this.refreshCounts()
      this.resetSelection()
    },
    refreshCounts() {
      this.$apollo.queries.collectionTotalCount.refresh()
      this.$apollo.queries.collectionFilteredCount.refresh()
    },
    resetSelection() {
      this.internalSelectAll = false
      this.internalSelectedFeatures = []
      this.internalSelectedFeature = {}
    },
    iterateOverFeatures (backward = false) {
      if (this.value.length) {
        if (!this.selectedFeature) {
          this.internalSelectedFeature = this.value[0]
        } else {
          const currentIndex = this.value.findIndex(feature => feature.id === this.selectedFeature.id)
          if ((!backward && currentIndex < this.value.length - 1) || (backward && currentIndex > 0)) {
            const newIndex = backward ? currentIndex - 1 : currentIndex + 1
            this.internalSelectedFeature = this.value[newIndex]
          }
        }
      }
    },
    nextFeature() {
      this.iterateOverFeatures()
    },
    previousFeature() {
      this.iterateOverFeatures(true)
    },
    scrollTo (feature) {
      this.$refs.fetcher.scrollTo(feature)
    }
  },
}
</script>

<template>
  <div class="d-flex flex-column flex-grow-1 overflow-y-hidden">
    <v-layout
      v-if="$apollo.queries.STACCollection.loading"
      justify-center
      align-center
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="120"
        width="10"
      />
    </v-layout>
    <template v-else>
      <STACExplorerFilters
        ref="filters"
        v-model="internalCriterias"
        :geometry="geometry"
        :collection="STACCollection"
        :order-by.sync="internalOrderBy"
        :advancedOrderBy="advancedOrderBy"
        wrappable
        :wrapped.sync="internalWrapFilters"
        :disableIdImport="disableIdImport"
        class="overflow-y-hidden"
      >
        <template v-slot:header-append="{ criterias: filters }">
          <STACExplorerExactCount
            v-show="!hideExactCount"
            v-model="internalTotalCount"
            :collection="collection"
            :criterias="filters"
            :exact-count.sync="exactCount"
            class="ml-auto"
          />
        </template>
        <template #default="{ criterias: filters }">
          <v-divider />
          <v-card
            v-if="multiselect"
            tile
            flat
          >
            <v-card-text class="pt-0 d-flex">
              <v-checkbox
                v-model="internalSelectAll"
                hide-details
                :disabled="queryLoading"
              >
                <template #label>
                  Select all
                </template>
              </v-checkbox>
              <v-spacer />
              <div
                v-if="imageCount > 0"
                class="d-flex mt-5"
              >
                <span v-if="selectAll && !exactCount">More than&nbsp;</span> {{ imageCount | formatNumber }} images selected
                (<a
                  class="text-link"
                  :class="{ 'link-disabled': queryLoading }"
                  @click="resetSelection"
                >
                  Reset selection
                </a>)
              </div>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-slide-y-transition hide-on-leave>
            <div
              v-if="showHiddenCount && hiddenCount > 0 && !$apollo.queries.collectionFilteredCount.loading && !$apollo.queries.collectionTotalCount.loading"
            >
              <div class="pa-2">
                <!-- class="pa-2 warning white--text" -->
                <v-alert transition="scale-transition" color="primary" outlined class="ma-0 pa-2" :style="`box-shadow: inset 40px 0 var(--v-primary-base)`">
                  <template v-slot:prepend>
                    <!-- TODO: tooltip -->
                    <v-icon color="white">mdi-information</v-icon>
                  </template>
                  <div class="pl-5 d-flex">
                    <b>{{ hiddenCount }}</b>&nbsp;feature<span v-if="hiddenCount > 1">s</span>&nbsp;hidden
                    <v-tooltip right max-width="250">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small class="mx-2">mdi-help-circle</v-icon>
                      </template>
                      <span>
                        <b>{{ hiddenCount }}</b> feature<span v-if="hiddenCount > 1">s are</span><span v-else> is</span> currently hidden because <span v-if="hiddenCount > 1">they do</span><span v-else>it does</span> not match filters.
                      </span>
                    </v-tooltip>
                    <v-spacer/>
                    <!-- TODO -->
                    <!-- <v-btn text small disabled>
                      show features
                    </v-btn> -->
                  </div>
                </v-alert>
              </div>
              <v-divider />
            </div>
          </v-slide-y-transition>
          <v-progress-linear
            indeterminate
            color="primary"
            class="px-auto"
            v-if="internalIngestLoading && internalValue.length"
          />
          <!-- <slot name="prepend"> -->
          <!-- </slot> -->
          <STACExplorerFetcher
            ref="fetcher"
            v-model="internalValue"
            :virtual-scroll="virtualScroll"
            :collection="collection"
            :criterias="filters"
            :order-by="internalOrderBy"
            :select-all="selectAll"
            :loading.sync="queryLoading"
            :selected-features.sync="internalSelectedFeatures"
            :selected-feature.sync="internalSelectedFeature"
            :multiselect="multiselect"
            :mandatory="mandatory"
            :target-collection="targetCollection"
            @created="refreshCounts"
            @deleted="refreshCounts"
          >
            <template v-slot:nodata>
              <v-layout
                v-if="internalIngestLoading"
                justify-center
                class="mt-5 mb-15"
              >
                <v-progress-circular
                  indeterminate
                  :size="50"
                  color="primary"
                  class="px-auto"
                />
              </v-layout>
              <slot name="nodata" v-else></slot>
            </template>

            <template v-slot:item="attrs">
              <slot name="item" v-bind="attrs" />
            </template>
          </STACExplorerFetcher>
        <!-- <slot name="append"></slot> -->
        </template>
      </STACExplorerFilters>
      <STACExplorerAssets v-if="enableCollectionAssets" :assets="collection.assets" />
    </template>
  </div>
</template>
