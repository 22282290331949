<script>
export default {
  name: 'CoreDarkThemeToggle',
  props: {
    default: {
      type: Boolean,
      default: false
    },
    tooltipPosition: {
      type: String,
      default: 'bottom'
    }
  },
  created() {
    const setting = localStorage.getItem('theme-dark')
    if (setting !== null) {
      this.$vuetify.theme.dark = JSON.parse(setting)
    } else {
      this.$vuetify.theme.dark = this.default
    }
  },
  computed: {
    icon() {
      return this.$vuetify.theme.dark ?
        'mdi-toggle-switch' : 'mdi-toggle-switch-off'
    }
  },
  methods: {
    toggleDarkTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('theme-dark', this.$vuetify.theme.dark.toString())
    }
  }
}
</script>

<template>
  <v-tooltip :bottom="tooltipPosition === 'bottom'" :left="tooltipPosition === 'left'" :right="tooltipPosition === 'right'" :top="tooltipPosition === 'top'">
    <template v-slot:activator="{ on }">
      <v-btn icon @click="toggleDarkTheme()" v-on="on">
        <v-icon class="rotate">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('toggleTheme') }}</span>
  </v-tooltip>
</template>

<style scoped>
.rotate::before {
  transform: rotate(90deg)
}
</style>

<i18n locale="fr">
{
  "toggleTheme": "Basculer entre thème sombre et thème clair"
}
</i18n>

<i18n locale="en">
{
  "toggleTheme": "Toggle Dark/Light theme"
}
</i18n>