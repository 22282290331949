<script>
export default {
  name: 'CoreLogo',
  props: {
    width: {
      type: Number,
      default: 150
    },
    height: {
      type: Number,
      default: 40
    },
    contain: {
      type: Boolean,
      default: true
    },
    logo: {
      type: String,
      default: require('../assets/logo_exwexs_black.png')
    }
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark
    }
  }
}
</script>

<template>
  <v-img :src="logo" :class="{ dark: isDark }" :width="width" :height="height" :contain="contain" />
</template>

<style scoped>
.dark {
  filter: invert(1)
}
</style>