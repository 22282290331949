<script>
import { DateTime } from 'luxon'

export default {
  name: 'CoreDatePicker',
  props: {
    label: {
      type: String,
      default: 'date'
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    showCurrent: {
      type: Boolean,
      default: true
    },
    minDate: String,
    maxDate: String,
    value: String,
    transition: {
      type: String,
      default: 'scale-transition'
    },
    onlyDate: {
      type: Boolean,
      default: false
    },
    nudgeLeft: {
      type: Number,
      default: 0
    },
    nudgeRight: {
      type: Number,
      default: 0
    },
    nudgeTop: {
      type: Number,
      default: 0
    },
    nudgeBottom: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'auto'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: 'mdi-calendar'
    },
    prependIcon: {
      type: String,
      default: ''
    },
    prependInnerIcon: {
      type: String,
      default: ''
    },
    top: {
      type: Boolean,
      default: true
    },
    pickerDate: String
  },
  data() {
    return {
      dateMenu: false,
      rules: {
        required: v => {
          if (this.required) return !!v || 'Required'
          return true
        }
      },
      datePicked: '',
      timePicked: ''
    }
  },
  computed: {
    internalDate: {
      get: function() {
        return this.value
      },
      set: function(v) {
        this.$emit('input', v)
      }
    },
    internalDatePicked: {
      get: function() {
        if (this.datePicked === '') return DateTime.fromISO(this.internalDate).toFormat('yyyy-MM-dd')
        return this.datePicked
      },
      set: function(v) {
        this.datePicked = v
      }
    },
    internalTimePicked: {
      get: function() {
        if (this.timePicked === '') return DateTime.fromISO(this.internalDate).toUTC().toFormat('HH:mm')
        return this.timePicked
      },
      set: function(v) {
        this.timePicked = v
      }
    },
    minTime() {
      if (this.minDate) {
        const date = DateTime.fromISO(this.datePicked)
        const minDate = DateTime.fromFormat(this.minDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
        if (date.year === minDate.year && date.month === minDate.month && date.day === minDate.day) return minDate.toFormat('HH:mm')
      }
      return null
    },
    maxTime() {
      if (this.maxDate) {
        const date = DateTime.fromISO(this.datePicked)
        const maxDate = DateTime.fromFormat(this.maxDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
        if (date.year === maxDate.year && date.month === maxDate.month && date.day === maxDate.day) return maxDate.toFormat('HH:mm')
      }
      return null
    },
    internalPickerDate: {
      get() {
        return this.pickerDate
      },
      set(v) {
        this.$emit('update:pickerDate', v)
      }
    }
  },
  methods: {
    closeDateMenu() {
      this.dateMenu = false
    },
    validDate() {
      const time = this.internalTimePicked.split(':')
      const date = this.internalDatePicked.split('-')
      const dateSelected = DateTime.utc().set({ year: date[0], month: date[1], day: date[2], hour: time[0], minute: time[1], second: 0, millisecond: 0 })
      this.internalDate = dateSelected.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'")
      this.closeDateMenu()
    }
  }
}
</script>

<template>
  <div>
    <v-menu v-model="dateMenu" :close-on-content-click="false" :transition="transition" offset-y :top="top" :nudge-left="nudgeLeft" :nudge-top="nudgeTop" :nudge-right="nudgeRight" :nudge-bottom="nudgeBottom" min-width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="internalDate" :label="label" :prepend-inner-icon="prependInnerIcon" :prepend-icon="prependIcon" :append-icon="appendIcon" :readonly="readonly" outlined dense v-bind="attrs" v-on="on" :rules="[rules.required]" :required="required" :hide-details="hideDetails" :disabled="disabled" :style="{ width: width }" :clearable="clearable"></v-text-field>
      </template>
      <template v-if="onlyDate">
        <v-date-picker v-model="internalDate" @input="dateMenu = false" :show-current="showCurrent" :min="minDate" :max="maxDate" no-title color="primary" :picker-date.sync="internalPickerDate" />
      </template>
      <template v-else>
        <v-card >
          <v-date-picker v-model="internalDatePicked" no-title color="primary" :width="250" flat :show-current="showCurrent" :min="minDate" :max="maxDate" :picker-date.sync="internalPickerDate" v-if="dateMenu" />
          <v-time-picker v-model="internalTimePicked" format="24hr" flat style="transform:scale(0.80); margin-right:-29px;" class="mt-n10 mb-n9 ml-n9" :min="minTime" :max="maxTime" color="primary" v-if="dateMenu" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" outlined small @click="closeDateMenu">Cancel</v-btn>
            <v-btn color="primary" outlined small @click="validDate">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-menu>
  </div>
</template>