import { render, staticRenderFns } from "./XMenuMore.vue?vue&type=template&id=b1281e4a&"
import script from "./XMenuMore.vue?vue&type=script&lang=js&"
export * from "./XMenuMore.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_fda74d0dd233d5440f16000c160c219e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_be6ad1899880d627e43945eca176d74c/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemAction,VListItemTitle,VMenu,VTooltip})
