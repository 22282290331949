/* eslint-disable no-console */
import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log('App is being served from cache by a service worker.')
    },
    registered(registration) {
      console.log('Registered')
      setInterval(() => {
        console.log('Checking updates')
        registration.update()
      }, 1000 * 60 * 10)
    },
    cached() {
      console.log('Content has beed cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('Dispatch swUpdated')
      document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }))
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
// }
