<script>
export default {
  methods: {
    formatFileSize(v, si = false, dp = 1, factor = 0) {
      return this.$options.filters.formatFileSize(v, si, dp, factor)
    },
    roundNumber(v, d, split) {
      return this.$options.filters.roundNumber(v, d, split)
    },
    formatNumber(v) {
      return this.$options.filters.formatNumber(v)
    },
    splitCamelCase(v) {
      return this.$options.filters.splitCamelCase(v)
    }
  },
  filters: {
    formatFileSize(v, si = false, dp = 1, factor = 0) {
      v *= Math.pow(10, factor)
      const tresh = si ? 1000 : 1024
      if (Math.abs(v) < tresh) {
        return `${v.toFixed(dp)} B`
      }
      const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['kiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10**dp
      do {
        v /= tresh
        ++u
      } while (Math.round(Math.abs(v) * r) / r >= tresh && u < units.length - 1)

      const w = new Intl.NumberFormat('en-US').format(v.toFixed(dp))
      return `${w} ${units[u]}`
    },
    roundNumber(v, d, split = true) {
      if (!v) v = 0
      if (!d) d = 0
      v = Math.round(v * Math.pow(10, d)) / Math.pow(10, d)
      if (v >= 10000 && split) {
        const w = new Intl.NumberFormat('en-US').format(Math.round(v / 1000))
        return `${w} k`
      }
      return new Intl.NumberFormat('en-US').format(v)
    },
    formatNumber(v) {
      if (v) return new Intl.NumberFormat('en-US').format(v)
      return 0
    },
    splitCamelCase(v) {
      if (v) {
        const w = v.replace(/([a-z])([A-Z])/g, '$1 $2')
        return w.charAt(0).toUpperCase() + w.slice(1)
      }
      return ''
    }
  }
}
</script>
