<script>
export default {
  name: 'XSubheader'
}
</script>

<template>
  <v-subheader>
    <slot></slot>
  </v-subheader>
</template>
