import { render, staticRenderFns } from "./CoreNotifierAlert.vue?vue&type=template&id=c4a10c84&"
import script from "./CoreNotifierAlert.vue?vue&type=script&lang=js&"
export * from "./CoreNotifierAlert.vue?vue&type=script&lang=js&"
import style0 from "./CoreNotifierAlert.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_fda74d0dd233d5440f16000c160c219e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_be6ad1899880d627e43945eca176d74c/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VAlert,VExpansionPanel,VExpansionPanelContent})
