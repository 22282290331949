<script>
export default {
  name: 'XFormField',
  props: {
    value: [String, Boolean],
    type: {
      type: String,
      default: 'text',
      validator: value => ['text', 'checkbox'].indexOf(value) !== -1
    },
    label: String,
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    hint: String,
    persistentHint: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<template>
  <div>
    <v-text-field
      v-if="type === 'text'"
      v-model="internalValue"
      :label="label"
      :outlined="outlined"
      :dense="dense"
      :required="required"
      :rules="rules"
      :disabled="disabled"
      :clearable="clearable"
      :hide-details="hideDetails"
      :hint="hint"
      :persistent-hint="persistentHint"
      :placeholder="placeholder"
      :readonly="readonly"
    />
    <v-checkbox
      v-if="type === 'checkbox'"
      v-model="internalValue"
      :label="label"
      :dense="dense"
      :disabled="disabled"
      :hide-details="hideDetails"
      :hint="hint"
      :persistent-hint="persistentHint"
      :rules="rules"
      :readonly="readonly"
    />
  </div>
</template>