import Users from '@/views/Users'
import Unauthorized from '@/views/Unauthorized'

export default function makeRoutes (router) {
  return [
    {
      path: '*',
      redirect: { name: 'users' }
    },
    {
      path: '/',
      redirect: { name: 'users' }
    },
    {
      path: '/users',
      name: 'users',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: Users
    },
    {
      path: '/asyst',
      name: 'asyst',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "Asyst" */ './views/Asyst'),
      redirect: { name: 'indicators' },
      children: [
        {
          path: 'indicators',
          name: 'indicators',
          component: () => import(/* webpackChunkName: "Asyst" */ './components/asyst/Indicators')
        },
        {
          path: 'indicatorConfigs',
          name: 'indicatorConfigs',
          component: () => import(/* webpackChunkName: "Asyst" */ './components/asyst/IndicatorConfigs')
        },
        {
          path: 'userConfigs',
          name: 'userConfigs',
          component: () => import(/* webpackChunkName: "Asyst" */ './components/asyst/UserConfigs')
        }
      ]
    },
    {
      path: '/datum',
      name: 'datum',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "Datum" */ './views/Datum'),
      redirect: { name: 'datum.configs' },
      children: [
        {
          path: 'quotas',
          name: 'datum.quotas',
          component: () => import(/* webpackChunkName: "Datum" */ './components/datum/DatumQuotas'),
          children: [
            {
              path: 'new',
              name: 'datum.quotas.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Datum" */ './components/datum/AddEditQuota')
              }
            },
            {
              path: ':id/edit',
              name: 'datum.quotas.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Datum" */ './components/datum/AddEditQuota')
              }
            },
            {
              path: ':id/increment',
              name: 'datum.quotas.increment',
              components: {
                dialog: () => import(/* webpackChunkName: "Datum" */ './components/datum/IncrementDecrementQuota')
              }
            },
            {
              path: ':id/decrement',
              name: 'datum.quotas.decrement',
              components: {
                dialog: () => import(/* webpackChunkName: "Datum" */ './components/datum/IncrementDecrementQuota')
              }
            },
            {
              path: ':id/history',
              name: 'datum.quotas.history',
              components: {
                dialog: () => import(/* webpackChunkName: "Datum" */ './components/datum/HistoryQuota')
              }
            },
            {
              path: 'send-report',
              name: 'datum.quotas.sendReport',
              components: {
                dialog: () => import(/* webpackChunkName: "Datum" */ './components/datum/DatumSendReport')
              }
            }
          ]
        },
        {
          path: 'quotasHistory',
          name: 'datum.quotasHistory',
          component: () => import(/* webpackChunkName: "Datum" */ './components/datum/DatumQuotasHistory'),
        },
        {
          path: 'configs',
          name: 'datum.configs',
          component: () => import(/* webpackChunkName: "Datum" */ './components/datum/DatumConfigs'),
          children: [
            {
              path: 'new',
              name: 'datum.configs.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Datum" */ './components/datum/AddEditConfig')
              }
            },
            {
              path: ':id/edit',
              name: 'datum.configs.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Datum" */ './components/datum/AddEditConfig')
              }
            }
          ]
        }
      ]
    },
    {
      path: '/pathfinder',
      name: 'pathfinder',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "Pathfinder" */ './views/Pathfinder'),
      redirect: { name: 'configs' },
      children: [
        {
          path: 'configs',
          name: 'configs',
          component: () => import(/* webpackChunkName: "Pathfinder" */ './components/pathfinder/PathfinderConfigs'),
          children: [
            {
              path: 'new',
              name: 'pathfinder.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Pathfinder" */ './components/pathfinder/AddEditConfig')
              }
            },
            {
              path: ':id/edit',
              name: 'pathfinder.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Pathfinder" */ './components/pathfinder/AddEditConfig')
              }
            }
          ]
        },
        {
          path: 'tracks',
          name: 'tracks',
          component: () => import(/* webpackChunkName: "Pathfinder" */ './components/pathfinder/PathfinderTracking'),
          children: [
            {
              path: 'delete',
              name: 'tracks.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Pathfinder" */ './components/pathfinder/DeletePathfinderTrackDialog')
              }
            }
          ]
        }
      ]
    },
    {
      path: '/status',
      name: 'status',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "Status" */ './views/Status'),
      redirect: { name: 'status.overview' },
      children: [
        {
          path: 'overview',
          name: 'status.overview',
          component: () => import(/* webpackChunkName: "Status" */ './components/status/Overview')
        },
        {
          path: 'pvcs',
          name: 'status.pvcs',
          component: () => import(/* webpackChunkName: "Status" */ './components/status/PvcHistory')
        },
        {
          path: 'nodes',
          name: 'status.nodes',
          component: () => import(/* webpackChunkName: "Status" */ './components/status/NodeHistory')
        }
      ]
    },
    {
      path: '/t-rust',
      name: 't-rust',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "Trust" */ './views/Trust'),
      redirect: { name: 't-rust.endpoint' },
      children: [
        {
          path: 'endpoints',
          name: 't-rust.endpoint',
          component: () => import(/* webpackChunkName: "Trust" */ './components/trust/Endpoint'),
          children: [
            {
              path: 'new',
              name: 'endpoint.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/EndpointAddEditDialog')
              }
            },
            {
              path: ':id/edit',
              name: 'endpoint.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/EndpointAddEditDialog')
              }
            },
            {
              path: ':id/addLayer',
              name: 'endpoint.addLayer',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/AddLayerToEndpoint')
              }
            },
            {
              path: ':id/editLayer',
              name: 'endpoint.editLayer',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/AddLayerToEndpoint')
              }
            },
            {
              path: ':id/removeLayer',
              name: 'endpoint.removeLayer',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/DeleteLayerToEndpointDialog')
              }
            },
            {
              path: 'delete',
              name: 'endpoint.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/EndpointDeleteDialog')
              }
            }
          ]
        },
        {
          path: 'layers',
          name: 't-rust.layers',
          component: () => import(/* webpackChunkName: "Trust" */ './components/trust/Layer'),
          children: [
            {
              path: 'new',
              name: 'layer.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/LayerAddEditDialog')
              }
            },
            {
              path: ':id/edit',
              name: 'layer.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/LayerAddEditDialog')
              }
            },
            {
              path: ':id/resync',
              name: 'layer.resync',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/LayerResyncDialog')
              }
            },
            {
              path: 'delete',
              name: 'layer.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/LayerDeleteDialog')
              }
            }
          ]
        },
        {
          path: 'legends',
          name: 't-rust.legends',
          component: () => import(/* webpackChunkName: "Trust" */ './components/trust/Legend'),
          children: [
            {
              path: 'new',
              name: 'legend.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/LegendAddEditDialog')
              }
            },
            {
              path: ':id/edit',
              name: 'legend.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/LegendAddEditDialog')
              }
            },
            {
              path: 'delete',
              name: 'legend.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/LegendDeleteDialog')
              }
            }
          ]
        },
        {
          path: 'colormaps',
          name: 't-rust.colormaps',
          component: () => import(/* webpackChunkName: "Trust" */ './components/trust/Colormap'),
          children: [
            {
              path: 'new',
              name: 'colormap.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/ColormapAddEditDialog')
              }
            },
            {
              path: ':id/edit',
              name: 'colormap.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/ColormapAddEditDialog')
              }
            },
            {
              path: 'delete',
              name: 'colormap.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/ColormapDeleteDialog')
              }
            }
          ]
        },
        {
          path: 'vectorstyles',
          name: 't-rust.vectorstyles',
          component: () => import(/* webpackChunkName: "Trust" */ './components/trust/VectorStyle'),
          children: [
            {
              path: 'new',
              name: 'vectorstyle.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/VectorStyleAddEditDialog')
              }
            },
            {
              path: ':id/edit',
              name: 'vectorstyle.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/VectorStyleAddEditDialog')
              }
            },
            {
              path: 'delete',
              name: 'vectorstyle.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Trust" */ './components/trust/VectorStyleDeleteDialog')
              }
            }
          ]
        }
      ]
    },
    {
      path: '/jobs',
      name: 'jobs',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "Jobs" */ './views/Jobs'),
      redirect: { name: 'jobs.job' },
      children: [
        {
          path: 'pool',
          name: 'jobs.pool',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/Pool'),
          children: [
            {
              path: 'new',
              name: 'jobs.pool.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/PoolAddEditDialog')
              }
            },
            {
              path: ':id/edit',
              name: 'jobs.pool.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/PoolAddEditDialog')
              }
            }
          ]
        },
        {
          path: 'process',
          name: 'jobs.process',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/Process'),
          children: [
            {
              path: ':identifier/:version/detail',
              name: 'process.detail',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/ProcessView')
              }
            }
          ]
        },
        {
          path: 'job',
          name: 'jobs.job',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/Job'),
          children: [
            {
              path: 'new',
              name: 'jobs.job.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobAddDialog')
              }
            },
            {
              path: ':jobId/logs',
              name: 'jobs.job.logs',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobLogsAndErrorsDialog')
              }
            },
            {
              path: ':jobId/start',
              name: 'jobs.job.start',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobStartDialog')
              }
            },
            {
              path: ':jobId/archive',
              name: 'jobs.job.archive',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobArchiveDialog')
              }
            },
            {
              path: ':jobId/retry',
              name: 'jobs.job.retry',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobRetryDialog')
              }
            },
            {
              path: ':jobId/delete',
              name: 'jobs.job.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobDeleteDialog')
              }
            }
          ]
        },
        {
          path: 'job/:jobId/view',
          name: 'jobs.job.view',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobView'),
          children: [
            {
              path: 'logs',
              name: 'jobs.job.view.logs',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobLogsAndErrorsDialog')
              }
            },
            {
              path: 'retry',
              name: 'jobs.job.view.retry',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobRetryDialog')
              }
            },
            {
              path: 'cancel',
              name: 'jobs.job.view.cancel',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobCancelDialog')
              }
            },
            {
              path: 'archive',
              name: 'jobs.job.view.archive',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobArchiveDialog')
              }
            },
            {
              path: 'delete',
              name: 'jobs.job.view.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobDeleteDialog')
              }
            },
            {
              path: 'deleteFromBatchJob',
              name: 'jobs.job.view.deleteFromBatchJob',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobJobDeleteDialog')
              }
            }
          ]
        },
        {
          path: 'batchjob',
          name: 'jobs.batchjob',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJob'),
          children: [
            {
              path: 'new',
              name: 'jobs.batchjob.new',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobAddDialog')
              }
            },
            {
              path: ':batchJobId/start',
              name: 'jobs.batchjob.start',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobStartDialog')
              }
            },
            {
              path: ':batchJobId/retry',
              name: 'jobs.batchjob.retry',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobRetryDialog')
              }
            },
            {
              path: ':batchJobId/archive',
              name: 'jobs.batchjob.archive',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobArchiveDialog')
              }
            },
            {
              path: ':batchJobId/delete',
              name: 'jobs.batchjob.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobDeleteDialog')
              }
            }
          ]
        },
        {
          path: 'batchjob/:batchJobId/view/',
          name: 'jobs.batchjob.view',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobView'),
          children: [
            {
              path: 'edit',
              name: 'jobs.batchjob.view.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobEditDialog')
              }
            },
            {
              path: 'start',
              name: 'jobs.batchjob.view.start',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobStartDialog')
              }
            },
            {
              path: 'flush',
              name: 'jobs.batchjob.view.flush',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobFlushDialog')
              }
            },
            {
              path: 'cancel',
              name: 'jobs.batchjob.view.cancel',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobCancelDialog')
              }
            },
            {
              path: 'retry',
              name: 'jobs.batchjob.view.retry',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobRetryDialog')
              }
            },
            {
              path: 'archive',
              name: 'jobs.batchjob.view.archive',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobArchiveDialog')
              }
            },
            {
              path: 'delete',
              name: 'jobs.batchjob.view.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobDeleteDialog')
              }
            },
            {
              path: 'force',
              name: 'jobs.batchjob.view.force',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/BatchJobForceDialog')
              }
            }
          ]
        },
        {
          path: 'jobexecutor',
          name: 'jobs.executor',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/JobExecutor')
        },
        {
          path: 'pipeline',
          name: 'pipeline.list',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/Pipeline'),
          children: [
            {
              path: 'create',
              name: 'jobs.pipeline.create',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/PipelineCreateDialog')
              }
            },
            {
              path: ':pipelineId/edit',
              name: 'jobs.pipeline.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/PipelineEditDialog')
              }
            },
            {
              path: ':pipelineId/enable',
              name: 'jobs.pipeline.enable',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/PipelineEnableDialog')
              }
            },
            {
              path: ':pipelineId/disable',
              name: 'jobs.pipeline.disable',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/PipelineDisableDialog')
              }
            },
            {
              path: 'delete',
              name: 'jobs.pipeline.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/PipelinesDeleteDialog')
              }
            }
          ]
        },
        {
          path: 'datasource',
          name: 'datasource.list',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/Datasource'),
          children: [
            {
              path: 'create',
              name: 'jobs.datasource.create',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceCreateDialog')
              }
            },
            {
              path: ':datasourceId/bootstrap',
              name: 'jobs.datasource.bootstrap',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceBootstrapDialog')
              }
            },
            {
              path: ':datasourceId/edit',
              name: 'jobs.datasource.edit',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceEditDialog')
              }
            },
            {
              path: ':datasourceId/enable',
              name: 'jobs.datasource.enable',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceEnableDialog')
              }
            },
            {
              path: ':datasourceId/disable',
              name: 'jobs.datasource.disable',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceDisableDialog')
              }
            },
            {
              path: ':datasourceId/addPipeline',
              name: 'jobs.datasource.addPipeline',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceAddPipelineDialog.vue')
              }
            },
            {
              path: ':pipelineId/enablePipeline',
              name: 'jobs.datasource.pipeline.enable',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/PipelineEnableDialog')
              }
            },
            {
              path: ':pipelineId/disablePipeline',
              name: 'jobs.datasource.pipeline.disable',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/PipelineDisableDialog')
              }
            },
            {
              path: ':datasourceId/bind',
              name: 'jobs.datasource.bind',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceBindPipelineDialog')
              }
            },
            {
              path: ':datasourceId/:pipelineId/unbind',
              name: 'jobs.datasource.unbind',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceUnbindPipelineDialog')
              }
            },
            {
              path: 'delete',
              name: 'jobs.datasource.delete',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceDeleteDialog')
              }
            },
            {
              path: 'auto-config',
              name: 'jobs.datasource.auto-config',
              components: {
                dialog: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceAutoConfig')
              }
            }
          ]
        },
        {
          path: 'datasource/:datasourceId/view/',
          name: 'jobs.datasource.view',
          component: () => import(/* webpackChunkName: "Jobs" */ './components/jobs/DatasourceView')
        }
      ]
    },
    {
      path: '/stac-browser',
      name: 'stac-browser',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "STACBrowser" */ './views/STACBrowser'),
      redirect: { name: 'search' },
      children: [
        {
          path: 'root',
          name: 'root',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/Root')
        },
        {
          path: 'collections/:url',
          name: 'collections',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/Collections')
        },
        {
          path: 'collection/:url',
          name: 'collection',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/Collection')
        },
        {
          path: 'items/:url',
          name: 'items',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/Items')
        },
        {
          path: 'item/:url',
          name: 'item',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/Item')
        },
        {
          path: 'conformance/:url',
          name: 'conformance',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/Conformance')
        },
        {
          path: 'service-desc/:url',
          name: 'service-desc',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/ServiceDesc')
        },
        {
          path: 'search',
          name: 'search',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/Search')
        },
        {
          path: 'stac_catalogs',
          name: 'stac_catalogs',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/StacCatalogs'),
          children: [
            {
              path: 'new',
              name: 'stac_catalogs.new',
              components: {
                dialog: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/AddCatalog')
              }
            }
          ]
        },
        {
          path: 'stac_collections',
          name: 'stac_collections',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/StacCollections'),
          children: [
            {
              path: 'new',
              name: 'stac_collections.new',
              components: {
                dialog: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/AddCollection')
              }
            }
          ]
        },
        {
          path: 'stac_items',
          name: 'stac_items',
          component: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/StacItems'),
          children: [
            {
              path: 'new',
              name: 'stac_items.new',
              components: {
                dialog: () => import(/* webpackChunkName: "STACBrowser" */ './components/STACBrowser/AddItem')
              }
            }
          ]
        }
      ]
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      beforeEnter: router.authGuard,
      component: Unauthorized
    },
    {
      path: '/register',
      name: 'register',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "Register" */ './views/Register')
    },
    {
      path: '/timesheet',
      name: 'timesheet',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "TimeSheet" */ './views/TimeSheet')
    },
    {
      path: '/moonseed',
      name: 'moonseed',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "Moonseed" */ './views/Moonseed'),
      children: [
        {
          path: 'new',
          name: 'moonseed.new',
          components: {
            dialog: () => import(/* webpackChunkName: "Moonseed" */ './components/moonseed/AddEditConfig')
          }
        },
        {
          path: ':id/edit',
          name: 'moonseed.edit',
          components: {
            dialog: () => import(/* webpackChunkName: "Moonseed" */ './components/moonseed/AddEditConfig')
          }
        }
      ]
    },
    {
      path: '/nrt',
      name: 'nrt',
      beforeEnter: router.makeAuthorizationGuard('ADMIN', 'unauthorized'),
      component: () => import(/* webpackChunkName: "Nrt" */ './views/Nrt'),
      children: [
        {
          path: 'new',
          name: 'nrt.new',
          components: {
            dialog: () => import(/* webpackChunkName: "Nrt" */ './components/nrt/AddSatellite')
          }
        },
        {
          path: 'view/:id',
          name: 'nrt.view',
          components: {
            dialog: ()  => import(/* webpackChunkName: "Nrt" */ './components/nrt/DetailSatellite')
          }
        }
      ]
    }
  ]
}
