<script>
const PRECISION = 10000
export default {
  filters: {
    formatExtent(value) {
      if (value) {
        if (value.type === 'Polygon') {
          return `[ ${Math.round(value.coordinates[0][0][0] * PRECISION) / PRECISION}, ${Math.round(value.coordinates[0][0][1] * PRECISION) / PRECISION}, ${Math.round(value.coordinates[0][2][0] * PRECISION) / PRECISION}, ${Math.round(value.coordinates[0][2][1] * PRECISION) / PRECISION} ]`
        } else if (value.type === 'Point') {
          return `[ ${Math.round(value.coordinates[0] * PRECISION) / PRECISION}, ${Math.round(value.coordinates[1] * PRECISION) / PRECISION} ]`
        }
      }
    }
  }
}
</script>
