<script>
import { GET_APP_STATE } from '../store/auth/queries'

export default {
  name: 'CoreSideMenu',
  props: {
    // user: {
    //   type: Object,
    //   default: () => ({
    //     isLogged: true,
    //     username: 'user'
    //   })
    // },
    autoState: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'App'
    },
    menu: {
      type: Array,
      required: true
    },
    clipped: {
      type: Boolean,
      default: true
    },
    value: Boolean,
    fixed: {
      type: Boolean,
      default: true
    },
    defaultMinify: {
      type: Boolean,
      default: true
    },
    user: Object,
    width: {
      type: Number,
      default: 220
    },
    vueEnv: {
      type: String,
      default: null
    }
  },
  apollo: {
    app: GET_APP_STATE
  },
  data () {
    return {
      minify: false,
      app: {}
    }
  },
  mounted() {
    const setting = localStorage.getItem('menu-minify')
    if (setting !== null) {
      this.minify = JSON.parse(setting)
    } else {
      this.minify = this.defaultMinify
    }
  },
  computed: {
    profilePict() {
      if (this.user.avatar) return this.user.avatar
      return ''
    },
    usernameDisplayed() {
      if (this.user.isLogged) {
        return this.user.username[0].toUpperCase() + this.user.username.slice(1)
      }
      return ''
    },
    subGroups() {
      return this.menu.filter(i => i.subgroup && !(this.app.authLevel !== 'ADMIN' && i.admin))
      // return this.menu.filter(i => i.subgroup)
    },
    flatItems() {
      return this.menu.filter(i => !i.subgroup && !(this.app.authLevel !== 'ADMIN' && i.admin))
      // return this.menu.filter(i => !i.subgroup)
    }
  },
  methods: {
    toggleMinify() {
      this.minify = !this.minify
      localStorage.setItem('menu-minify', this.minify)
    }
  },
  filters: {
    toUpperCase(value) {
      if (!value) return
      return value.toUpperCase()
    }
  }
}
</script>

<template>
  <v-navigation-drawer
    v-if="!autoState || app.isLoggedIn"
    app
    :mini-variant="minify"
    mobile-breakpoint="200"
    :clipped="clipped"
    :width="width"
    :style="{
      'border-right': '1px solid',
      'border-color': $vuetify.theme.dark ? '#424242' : '#e0e0e0',
    }"
  >

    <!-- <v-list dense avatar>
      <v-list-item-group> -->
        <!-- <v-list-item>
          <v-list-item-avatar>
            <img :src="profilePict"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 white--text">{{ usernameDisplayed }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      <!-- </v-list-item-group> -->

      <!-- <v-divider light></v-divider> -->
    <v-list-item v-if="user" class="px-2" style="height: 48px;">
      <v-list-item-avatar>
        <v-img v-if="user.avatar" :src="user.avatar"></v-img>
        <v-icon large v-else>mdi-account-circle</v-icon>
      </v-list-item-avatar>

      <v-list-item-title>{{ user.username }}</v-list-item-title>

      <!-- <v-btn
        icon
        @click.stop="mini = !mini"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn> -->
    </v-list-item>

    <v-divider v-if="user" style="border-width: thin 2px 1px; border-color: rgba(0, 0, 0, 0.25)"></v-divider>

    <v-list-item v-if="vueEnv" class="px-0" style="height: 48px;" :class="{ 'info': vueEnv === 'development', 'error': vueEnv === 'production' }">
      <v-list-item-title class="text-center white--text font-weight-bold text-uppercase" style="letter-spacing: -1px">{{ vueEnv === 'production' ? 'Prod' : 'Dev' }}</v-list-item-title>
    </v-list-item>

    <v-divider v-if="vueEnv"></v-divider>

    <v-list nav dense>
      <v-list-item-group>
        <v-list-item v-for="item in flatItems" :key="item.title" :to="item.to" :disabled="item.disabled">
          <v-list-item-icon>
            <v-icon :color="item.color" :disabled="item.disabled" >{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-for="group in subGroups" :key="group.title" :prepend-icon="group.icon" append-icon="mdi-menu-down">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ group.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="item in group.subgroup" :key="item.title" :to="item.to" :disabled="item.disabled">
            <v-list-item-icon>
              <v-icon :color="item.color" :disabled="item.disabled">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>

    <div class="posBottom">
      <slot name="bottom-content" ></slot>
    </div>

    <template v-slot:append>
      <!-- <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-fade-transition group mode="out-in">
              <v-img :src="require('@/assets/images/logo_exwexs.png')" width="200" v-if="!minify" key="img_full"/>
              <v-img :src="require('@/assets/images/exwexs_logo_white_small.png')" width="60" v-if="minify" key="img_small"/>
            </v-fade-transition>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-divider />
      <v-btn block text @click="toggleMinify" color="grey">
        <v-icon :class="{ rotate: minify }">mdi-chevron-left</v-icon>
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>

<style scoped>
.rotate {
  transform: rotate(180deg);
  transition-duration: 500ms;
}
.posBottom {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  /* width: 100%; */
}
</style>