<script>
import GROUP_CREATE_MUTATION from '@/graphql/mutation/user/GroupCreate.gql'
import { CoreContentToolbar } from '@exwexs/vue-core'

export default {
  name: 'AddGroup',
  components: {
    CoreContentToolbar
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      valid: true,
      groupName: '',
      rules: {
        required: v => !!v || 'Required.',
        counter: v => v.length <= 100 || 'Max 100 characters'
      }
    }
  },
  methods: {
    closeGroup() {
      this.groupName = ''
      this.$emit('close')
    },
    saveGroup() {
      if (this.$refs.formGroup.validate()) {
        this.$apollo.mutate({
          mutation: GROUP_CREATE_MUTATION,
          variables: {
            inputs: {
              name: this.groupName
            }
          }
        }).then(() => {
          this.closeGroup()
        }).catch(e => {
          this.$notifier.error(e)
        })
      }
    },
  },
  computed: {
    internalShow: {
      get() {
        return this.show
      },
      set (v) {
        this.$emit('update:show', v)
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="internalShow" max-width="500px" persistent>
    <v-card>
      <CoreContentToolbar title="Add Group"/>
      <v-divider/>
      <v-form ref="formGroup" v-model="valid" @submit.prevent="saveGroup">
        <v-card-text>
          <v-text-field outlined dense label="Name" v-model="groupName" :rules="[rules.required, rules.counter]" counter maxlength="100" required v-if="show" autofocus />
        </v-card-text>
        <v-divider/>
        <v-card-actions> 
          <v-spacer></v-spacer>
          <v-btn color="red darken-2" outlined @click="closeGroup">Close</v-btn>
          <v-btn color="green darken-2" outlined :disabled="!valid" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>