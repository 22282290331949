import gql from 'graphql-tag'
import Token from '../../models/token'
import { GET_APP_STATE, IS_LOGGED_IN } from './queries'

const TOKEN_KEY = 'token'

const typeDefs = gql`
type App {
  isLoggedIn: Boolean!
  token: JWT
  username: String
  authLevel: String
}

type Mutation {
  authenticate(token: String!): App
  deauthenticate: App
}

type Query {
  isLoggedIn: Boolean!
}
`

const resolvers = {
  Mutation: {
    authenticate: async (_, args, context) => {
      const { token } = args
      const { client } = context
      if (token) {
        localStorage.setItem(TOKEN_KEY, token)
        const parsedToken = new Token(token)

        const data = client.readQuery({ query: GET_APP_STATE })
        data.app.isLoggedIn = true
        data.app.username = parsedToken.getUsername()
        data.app.token = parsedToken.getRaw()
        data.app.authLevel = parsedToken.getAuthLevel()
        client.writeQuery({ query: GET_APP_STATE, data })
      }
    },
    deauthenticate: (_, __, { client }) => {
      localStorage.removeItem(TOKEN_KEY)
      client.clearStore()
      client.closeWebSocket()
    }
  },
  Query: {
    isLoggedIn: async (_, __, { client }) => {
      return client.readQuery({ query: IS_LOGGED_IN })
    }
  }
}

function init (cache) {
  console.log('vue-core: init app store')
  const previousToken = localStorage.getItem(TOKEN_KEY)
  const data = {
    app: {
      __typename: 'App',
      isLoggedIn: false,
      token: null,
      username: null,
      authLevel: null
    }
  }
  if (previousToken) {
    const token = new Token(previousToken)
    data.app.token = token.getRaw()
    data.app.username = token.getUsername()
    data.app.authLevel = token.getAuthLevel()
    data.app.isLoggedIn = true
  }

  cache.writeData({
    data
  })
}

export default {
  typeDefs,
  resolvers,
  init
}
