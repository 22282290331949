<script>
import { CoreContentToolbar } from '@exwexs/vue-core'
import formatExtent from '@/components/mixins/formatExtent'
import { DateTime } from 'luxon'

export default {
  name: 'TableGeneric',
  mixins: [
    formatExtent
  ],
  components: {
    CoreContentToolbar
  },
  props: {
    items: {
      type: Array,
      default() {
        return null
      }
    },
    count: {
      type: Number,
      default: -1
    },
    displayOptions: {
      type: Array,
      default() {
        return null
      }
    },
    title: {
      type: String,
      default: ''
    },
    footerProps: {
      type: Object,
      default() {
        return {
          itemsPerPageOptions: [5, 10, 25, 50, 100]
        }
      }
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },  
    options: Object,
    loading: {
      type: Boolean,
      default: false
    },
    tabExpand: {
      type: Boolean,
      default: false
    },
    expandLeft: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectedItems: Array,
    flat: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default() {
        return null
      }
    },
    noDataText: {
      type: String,
      default: 'No matching records founds'
    },
    noResultsText: {
      type: String,
      default: 'No matching records founds'
    },
    loadingText: {
      type: String,
      default: 'Loading datas ...'
    },
    noWrap: {
      type: Boolean,
      default: false
    },
    lineAction: {
      type: Function,
      default: null
    },
    itemKey: {
      type: String,
      default: 'id'
    }
  },
  computed: {
    internalPagination: {
      get() {
        return this.options
      },
      set(v) {
        this.$emit('update:options', v)
      }
    },
    internalSelectedItems: {
      get() {
        return this.selectedItems
      },
      set(v) {
        this.$emit('update:selectedItems', v)
      }
    },
    headers() {
      const headers = []
      if (this.tabExpand && this.expandLeft) headers.push({ text: '', value: 'data-table-expand' })
      headers.push(...this.displayOptions.map(o => {
        return { text: o.text, value: o.value, sortable: o.sortable, align: o.align }
      }))
      if (this.actions) headers.push({ text: 'Actions', value: 'actions', sortable: false, align: 'right' })
      if (this.tabExpand && !this.expandLeft) headers.push({ text: '', value: 'data-table-expand' })
      return headers
    }
  },
  filters: {
    slotData(value, slot) {
      if (slot.includes('.')) {
        const lst = slot.split('.')
        return lst.reduce((acc, cv) => {
          if (acc) return acc = acc[cv]
        }, value)
      }
      return value[slot]
    },
    formatData(value, format) {
      if (typeof format === 'function') return format(value)
      return value
    },
    arrayFromMultipleValue(value, options) {
      return options.list.map(o => value[o])
    },
    dateFormat(value, format) {
      if (!value) return ''
      return DateTime.fromISO(value).toUTC().toFormat(format)
    }
  },
  methods: {
    clickRow(item) {
      if (this.lineAction) this.lineAction(item)
    }
  }
}
</script>

<template>
  <v-container fluid>
    <CoreContentToolbar v-if="title !== ''">
      {{ title }}
      <template slot="items-append">
        <slot name="management-actions"></slot>
      </template>
    </CoreContentToolbar>

    <v-container fluid>
      <v-card :flat="flat">
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            :footer-props="footerProps"
            :server-items-length="count"
            :options.sync="internalPagination"
            :loading="loading"
            :dense="dense"
            :show-select="selectable"
            :show-expand="tabExpand"
            v-model="internalSelectedItems"
            :hide-default-footer="hideFooter"
            :hide-default-header="hideHeader"
            :no-data-text="noDataText"
            :no-results-text="noResultsText"
            :loading-text="loadingText"
            @click:row="clickRow"
            :item-key="itemKey"
          >
            <template v-slot:top>
              <slot name="tableTop"></slot>
            </template>

            <template v-for="(slot, i) in displayOptions" v-slot:[`item.${slot.value}`]="{ item }">
              <span :class="{ 'text-no-wrap': noWrap }" :key="i" v-if="slot.format === 'listTooltip'">
                <span v-for="[key, value] of Object.entries($options.filters.slotData(item, slot.value))" :key="key">
                  <v-tooltip bottom v-if="key !== '__typename'">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" :color="value ? 'success' : 'error'" class="ml-3">{{ slot.options.list[key].icon }}</v-icon>
                    </template>
                    <span>{{ slot.options.list[key].tooltip }}</span> 
                  </v-tooltip>
                </span>               
              </span>
              <span :class="{ 'text-no-wrap': noWrap }" :key="i" v-else-if="slot.format === 'arrayFromMultipleValue'">{{ item | arrayFromMultipleValue(slot.options) }}</span>
              <span :class="{ 'text-no-wrap': noWrap }" :key="i" v-else-if="slot.format === 'formatExtent'">{{ item | slotData(slot.value) | formatExtent }}</span>
              <span :class="{ 'text-no-wrap': noWrap }" :key="i" v-else-if="slot.format === 'specificMultipleValue'">{{ slot.options.formatFunction(item) }}</span>
              <span :class="{ 'text-no-wrap': noWrap }" :key="i" v-else-if="slot.format === 'specificHtml'" v-html="slot.options.formatFunction(item)"></span>
              <span :class="{ 'text-no-wrap': noWrap }" :key="i" v-else-if="slot.format === 'url'">
                <a :href="(slot.options && slot.options.prefixUrl) ? slot.options.prefixUrl + $options.filters.slotData(item, slot.value) : $options.filters.slotData(item, slot.value)" target="_blank">{{ item | slotData(slot.value) }}</a>
              </span>
              <span :key="i" v-else-if="slot.format === 'iconTrueFalse'">
                <v-icon color="success" v-if="$options.filters.slotData(item, slot.value)">mdi-check</v-icon>
                <v-icon color="error" v-else>mdi-close</v-icon>
              </span>
              <span :key="i" v-else-if="slot.format === 'multiIcon'">
                <v-icon v-if="slot.options.list[$options.filters.slotData(item, slot.value)]" :color="slot.options.list[$options.filters.slotData(item, slot.value)].color">{{ slot.options.list[$options.filters.slotData(item, slot.value)].icon }}</v-icon>
                <v-progress-circular v-else-if="slot.options.progress" :width="slot.options.progress.width" :size="slot.options.progress.size" :rotate="slot.options.progress.rotate" :value="item[slot.options.progress.value] || 0" :color="slot.options.progress.color" />
              </span>
              <span :key="i" v-else-if="slot.format === 'multiIconTooltip'">
                <v-tooltip bottom v-if="slot.options.list[$options.filters.slotData(item, slot.value)]">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="slot.options.list[$options.filters.slotData(item, slot.value)].color">{{ slot.options.list[$options.filters.slotData(item, slot.value)].icon }}</v-icon>
                  </template>
                  <span>{{ $options.filters.slotData(item, slot.value) }}</span>
                </v-tooltip>
              </span>
              <span :class="{ 'text-no-wrap': noWrap }" :key="i" v-else-if="slot.format === 'dateFormat'">{{ item | slotData(slot.value) | dateFormat(slot.options.format) }} </span>
              <span :class="{ 'text-no-wrap': noWrap }" :key="i" v-else-if="slot.format === 'iconBefore'">
                <v-icon v-if="item[slot.options.condition]" left :small="slot.options.small">{{ slot.options.icon }}</v-icon>
                {{ item | slotData(slot.value) }}
              </span>
              <span :key="i" v-else-if="slot.format === 'button'">
                <v-btn v-if="$options.filters.slotData(item, slot.value)" :small="slot.options.small" :outlined="slot.options.outlined" @click.stop="slot.options.action(item)">{{ slot.options.name(item) }} </v-btn>
              </span>
              <!-- <span :key="i" v-else-if="slot.format === 'buttonList'">
                <template>
                  <v-btn v-if="$options.filters.slotData(item, slot.value)" :small="slot.options.small" :outlined="slot.options.outlined" @click.stop="slot.options.action(item)">{{ slot.options.name(item) }} </v-btn>
                </template>
              </span> -->
              <span :class="{ 'text-no-wrap': noWrap }" :key="i" v-else>{{ item | slotData(slot.value) | formatData(slot.format) }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <span :class="{ 'text-no-wrap': noWrap }">
                <slot name="specificActionsBefore" :item="item" /> 

                <v-tooltip bottom v-for="(act, i) of actions" :key="i">
                  
                  <template v-slot:activator="{ on }" v-if="act.type === 'indeterminate'">
                    <v-progress-circular v-on="on" indeterminate v-if="item[act.condition]">
                      <v-icon>{{ act.icon }}</v-icon>
                    </v-progress-circular>
                  </template>

                  <template v-slot:activator="{ on }" v-else>
                    <v-btn v-on="on" icon @click="act.click(item)">
                      <v-icon>{{ act.icon }}</v-icon>
                    </v-btn>
                  </template>

                  <span>{{ act.tooltip }}</span>
                </v-tooltip>
                <slot name="specificActions" :item="item" /> 
              </span>
            </template>
            
            <template v-slot:expanded-item="{ headers, item }">
              <slot name="expandContent" :item="item" :headers="headers" />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>
