import gql from 'graphql-tag'

export const LOCAL_AUTHENTICATE = gql`
mutation ($token: String!) {
  authenticate(token: $token) @client
}
`

export const LOCAL_DEAUTHENTICATE = gql`
mutation {
  deauthenticate @client
}
`
