<script>
import XButton from './XButton'
import XTooltip from './XTooltip'

export default {
  name: 'XSimpleTable',
  components: {
    XButton,
    XTooltip
  },
  props: {
    headers: {
      type: Array,
      validator: value => value.length && value.every(v => 'text' in v && 'value' in v)
    },
    items: Array,
    actions: {
      type: Array,
      validator: value => !value.length || value.every(v => 'icon' in v && ('action' in v && typeof v.action === 'function') )
    },
    dense: {
      type: Boolean,
      default: false
    },
    fixedHeader: {
      type: Boolean,
      default: false
    },
    height: [Number, String]
  },
  methods: {
    getItemValue(item, headerValue) {
      const headerArray = headerValue.split('.')
      if (headerArray.length === 1) return item[headerValue]
      else {
        const it = item[headerArray[0]]
        headerArray.shift()
        return this.getItemValue(it, headerArray.join('.'))
      }
    }
  }
}
</script>

<template>
  <v-simple-table :dense="dense" :fixed-header="fixedHeader" :height="height">
    <thead>
      <tr>
        <th v-for="header of headers" :key="header.text" :class="{ 'text-left': header.align === 'left', 'text-center': header.align === 'center', 'text-right': header.align === 'right' }">{{ header.text }}</th>
        <th v-if="actions.length" class="text-right">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, i) of items" :key="i">
        <td v-for="header of headers" :key="header.value" :class="{ 'text-left': header.align === 'left', 'text-center': header.align === 'center', 'text-right': header.align === 'right' }">
          <slot :item="item" :name="`item.${header.value}`">{{ getItemValue(item, header.value) }}</slot>
          
        </td>
        <td class="d-flex flex-nowrap justify-end" v-if="actions.length">
          <template v-for="(act, i) of actions">
            <x-tooltip bottom :text="act.tooltip" v-if="act.tooltip" :key="`act_${i}`">
              <template slot-scope="{ on }">
                <div v-on="on">
                  <x-button :color="act.color" :disabled="act.disabled" @click="act.action(item)" :icon="act.icon" icon-only />
                </div>
              </template>
            </x-tooltip>
            <x-button v-else :key="`act_${i}`" :color="act.color" :disabled="act.disabled" @click="act.action(item)" :icon="act.icon" icon-only />
          </template>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>