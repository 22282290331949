<script>
export default {
  name: 'XDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '500px'
    },
    persistent: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="internalValue" :max-width="maxWidth" :persistent="persistent" :scrollable="scrollable">
    <slot></slot>
  </v-dialog>
</template>