<script>
export default {
  name: 'XMenuMore',
  props: {
    bottom: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    offsetY: {
      type: Boolean,
      default: false
    },
    offsetX: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Array,
      required: true,
      validator: value => value.length && value.every(v => 'text' in v && ('action' in v && typeof v.action === 'function'))
    },
    menuTitle: String
  }
}
</script>

<template>
  <v-menu
    :bottom="bottom"
    :top="top"
    :left="left"
    :right="right"
    :offset-y="offsetY"
    :offset-x="offsetX"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" small>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <span class="overline ml-4 grey--text" style="font-size: 0.9rem !important" v-if="menuTitle">{{ menuTitle }}</span>
      <template v-for="(item, i) of menu">
        <v-tooltip v-if="item.tooltip" :key="`menu_${i}`" :bottom="item.tooltipPosition === 'bottom'" :left="item.tooltipPosition === 'left'" :top="item.tooltipPosition === 'top'" :right="item.tooltipPosition === 'right'" >
          <template v-slot:activator="{ on }">
            <v-list-item @click.stop.prevent="item.action" v-on="on">
              <v-list-item-title :style="item.style">{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
        <v-list-item v-else @click.stop.prevent="item.action" :disabled="item.disabled" :key="`menu_${i}`">
          <v-list-item-title :style="item.style">{{ item.text }}</v-list-item-title>
          <v-list-item-action v-if="item.actionSup" v-html="item.actionSup" />
        </v-list-item>
      </template>
    </v-list>    
  </v-menu>
</template>
