'user strict'

const DEFAULT = {
  notifications: [],
  timeout: 6000
}

const DEFAULT_MESSAGE = {
  text: '',
  type: 'info',
  details: null,
  timeout: 6000
}

export default function makeNotifier(options = {}) {
  let store = Object.assign({}, DEFAULT, options)

  function notify(_message = {}) {
    const message = Object.assign({ date: Date.now() }, DEFAULT_MESSAGE, _message)
    store.notifications.unshift(message)
  }

  function info(text, timeout) {
    store.notify({ text, type: 'info', timeout })
  }

  function success(text, timeout) {
    store.notify({ text, type: 'success', timeout })
  }

  function warn(text, timeout) {
    store.notify({ text, type: 'warning', timeout })
  }

  function error(error, timeout) {
    let text = error.message || error
    const details = error.details || null
    store.notify({ text, details, type: 'error', timeout })
  }

  const notifier = (text, type, timeout) => () => notify({ text, type, timeout })

  const catcher = (text = null, returnValue = null) => error => {
    if (text) {
      error.originalMessage = error.message
      error.message = text
    }
    error(error)
    return returnValue
  }

  Object.defineProperties(store, {
    info: { value: info },
    success: { value: success },
    error: { value: error },
    warn: { value: warn },
    notifier: { value: notifier },
    catcher: { value: catcher },
    notify: { value: notify }
  })

  return store
}
