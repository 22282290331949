import fetchIntercept from 'fetch-intercept'
const TOKEN_KEY = 'token'

export default ({ apolloProvider, router, notifier = null, tokenKey = TOKEN_KEY }) => fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here
    return [url, config]
  },

  requestError: function (error) {
    if (notifier) {
      notifier.error('Request error: '+error.message)
    }
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error)
  },

  response: async function (response) {
    if (response.status === 403 || response.status === 401) {
      console.log('interceptor: 403 detected, token removed -> redirect to home')
      localStorage.removeItem(tokenKey)
      await router.push({ name: 'login' })
      apolloProvider.defaultClient.resetStore()
      // apolloProvider.defaultClient.cache.reset()
    }
    // Modify the reponse object
    return response
  },

  responseError: function (error) {
    // if (notifier) {
      // if (error.message === 'Failed to fetch') {
      //   notifier.error('Failed to fetch: API unavailable')
      // }
    // }
    // Handle an fetch error
    return Promise.reject(error)
  }
})