import { render, staticRenderFns } from "./CoreLogoutButton.vue?vue&type=template&id=c6be0efe&"
import script from "./CoreLogoutButton.vue?vue&type=script&lang=js&"
export * from "./CoreLogoutButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_fda74d0dd233d5440f16000c160c219e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CoreLogoutButton.vue?vue&type=custom&index=0&blockType=i18n&locale=fr"
if (typeof block0 === 'function') block0(component)
import block1 from "./CoreLogoutButton.vue?vue&type=custom&index=1&blockType=i18n&locale=en"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_be6ad1899880d627e43945eca176d74c/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VListItem,VListItemIcon,VListItemTitle,VTooltip})
