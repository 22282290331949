import { render, staticRenderFns } from "./CoreSideMenu.vue?vue&type=template&id=140a7408&scoped=true&"
import script from "./CoreSideMenu.vue?vue&type=script&lang=js&"
export * from "./CoreSideMenu.vue?vue&type=script&lang=js&"
import style0 from "./CoreSideMenu.vue?vue&type=style&index=0&id=140a7408&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_fda74d0dd233d5440f16000c160c219e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "140a7408",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_be6ad1899880d627e43945eca176d74c/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VDivider,VIcon,VImg,VList,VListGroup,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VNavigationDrawer})
