'use strict'

const authLevels = {
  VISITOR: { name: 'VISITOR', level: 0 },
  USER: { name: 'USER', level: 1 },
  SERVICE: { name: 'SERVICE', level: 98 },
  ADMIN: { name: 'ADMIN', level: 99 }
}

export default authLevels
