<script>
export default {
  name: 'STACExplorerFiltersIdsAutocomplete',
  props: {
    ids: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      comboModel: [],
      search: null
    }
  },
  computed: {
    internalIds: {
      get() {
        return this.ids
      },
      set(v) {
        this.$emit('update:ids', v)
      }
    },
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    filter (item, queryText, itemText) {
      if (item.header) return false
      const hasValue = val => val !== null ? val : ''
      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    }
  },
  watch: {
    internalValue (val, prev) {
      if (val.length === prev.length) return
      this.internalValue = val.map(v => {
        if (typeof v === 'string' && !this.internalIds.includes(v)) this.internalIds.push(v)
        return v
      })
    }
  }
}
</script>

<template>
  <v-combobox
    v-model="internalValue"
    :filter="filter"
    :hide-no-data="!search"
    :items="internalIds"
    :search-input.sync="search"
    label="Identifiers"
    multiple
    small-chips
    outlined
    dense
    clearable
  >
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading mr-2">Add</span>
        <v-chip label small>{{ search }}</v-chip>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attrs, item, parent, selected, index }">
      <v-chip
        v-if="item && item !== '' && index < 2"
        v-bind="attrs"
        :input-value="selected"
        label
        small
        class="mt-1"
      >
        <span class="pr-2">{{ item }}</span>
        <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
      </v-chip>
      <span v-if="index === 2" class="mt-1">
        (+ {{ internalValue.length - 2 }} others)
      </span>
    </template>
  </v-combobox>
</template>