import gql from 'graphql-tag'

export const GET_APP_STATE = gql`
query {
  app @client {
    isLoggedIn
    username
    authLevel
    token
  }
}
`

export const IS_LOGGED_IN = gql`
query { isLoggedIn @client }
`

