'use strict'

import authLevels from './authLevels'

function parse (_level) {
  return authLevels[_level]
}

function has (_token, _authLevel) {
  const userLevel = parse(_authLevel)
  const wantedLevel = parse(_token)
  if (Number(userLevel.level) >= Number(wantedLevel.level)) return true
  return false
}

function checkLevel (_token, _level) {
  return has(_token, _level)
}

export default checkLevel
