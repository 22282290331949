<script>
import formatingData from '../mixins/formatingData'
import COUNT_STAC_ITEMS_QUERY from '../graphql/query/STACItemsCount.gql'

export default {
  name: 'STACExplorerExactCount',
  mixins: [formatingData],
  props: {
    value: {
      type: Number,
      default: 0
    },
    collection: {
      type: String,
      required: true
    },
    criterias: {
      type: Object,
      default: () => ({})
    },
    exactCount: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    countSTACItem: {
      query: COUNT_STAC_ITEMS_QUERY,
      variables() {
        return {
          criterias: {
            ...this.criterias,
            collection: this.collection,
            exactCount: this.queryExactCount
          }
        }
      },
      update(data) {
        this.internalExactCount = this.queryExactCount
        this.internalTotalCount = data.countSTACItem
        return data.countSTACItem
      }
    }
  },
  data() {
    return {
      loading: false,
      queryExactCount: false
    }
  },
  computed: {
    internalExactCount: {
      get() {
        return this.exactCount
      },
      set(v) {
        this.$emit('update:exactCount', v)
      }
    },
    internalTotalCount: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    countColor() {
      return this.exactCount && !this.$apollo.queries.countSTACItem.loading
        ? 'success'
        : 'amber'
    }
  },
  watch: {
    collection() {
      this.queryExactCount = false
      this.$apollo.queries.countSTACItem.refresh()
    },
    criterias() {
      this.queryExactCount = false
      this.$apollo.queries.countSTACItem.refresh()
    }
  }
}
</script>

<template>
  <v-chip :color="countColor" outlined dark>
    <v-avatar :color="countColor + ' darken-1'" class="mr-2">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon @click.stop.prevent="queryExactCount = true" :loading="$apollo.queries.countSTACItem.loading" :disabled="internalExactCount" v-on="on">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
        </template>
        <span>Compute exact count</span>
      </v-tooltip>
    </v-avatar>
    <span v-show="!internalExactCount">&gt;&nbsp;</span>
    <span style="font-size: 0.9rem">{{ internalTotalCount | formatNumber }}</span>
    <v-icon right>mdi-equalizer</v-icon>
  </v-chip>
</template>
