<script>
export default {
  name: 'CoreBottomContainer',
  props: {
    minHeight: {
      type: Number,
      default: 0
    },
    showHidingButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: true
    }
  },
  computed: {
    height() {
      const size = this.show ? undefined : this.minHeight
      const borderColor = this.$vuetify.theme.dark ? '#424242' : '#e0e0e0'
      return this.show ? 
        `border-top: solid 1px; border-color: ${borderColor};` 
        : `border-top: solid 1px; border-color: ${borderColor}; height: ${size}px !important`
    }
  }
}
</script>

<template>
  <div class="bottomContaier" :style="height">
    <div 
      class="buttonContainer text-center"
      :style="{'position': 'absolute', 'pointer-events': 'none', 'transform': 'translateY(-25px', 'width': '100%'}"
      v-if="showHidingButton"
    >
      <v-btn fab outlined elevation-0 width="64px" :color="$vuetify.theme.dark ? '#424242' : '#e0e0e0'" :class="{'show-up': show, 'show-down': !show}" @click="show = !show" style="box-shadow: none"
      :style="{'pointer-events': 'auto', 'background-color': $vuetify.theme.dark ? '#1E1E1E' : '#FFFFFF'}"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <v-slide-y-reverse-transition hide-on-leave>
      <div>
        <slot></slot>
      </div>
    </v-slide-y-reverse-transition> 
  </div>
</template>

<style lang="sass">
.bottomContaier
  width: 100vw
  user-select: none
  .buttonContainer
    height: 25px
    overflow: hidden
    .show-up i
      transform: translateY(-13px) rotate(90deg)
      transtion-duration: 300ms
    .show-down i
      transform: translateY(-13px) rotate(-90deg)
      transtion-duration: 300ms
</style>