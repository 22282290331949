<script>
import CoreContentToolbar from './CoreContentToolbar'
import CoreLogo from './CoreLogo'
export default {
  name: 'CoreAboutDialog',
  components: {
    CoreContentToolbar,
    CoreLogo
  },
  props: {
    appName: String,
    appVersion: String,
    appDescription: String,
    logo: {
      type: String,
      default: require('../assets/logo_exwexs_black.png')
    },
    tooltipPosition: {
      type: String,
      default: 'bottom'
    },
    plainText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    close() {
      this.show = false
    },
    open() {
      this.show = true
    }
  }
}
</script>

<template>
  <div>
    <template v-if="plainText">
      <v-list-item @click="open">
        <v-list-item-icon><v-icon>mdi-information</v-icon></v-list-item-icon>
        <v-list-item-title>{{ $t('about') }}</v-list-item-title>
      </v-list-item>
    </template>
    <template v-else>
      <v-tooltip :bottom="tooltipPosition === 'bottom'" :left="tooltipPosition === 'left'" :right="tooltipPosition === 'right'" :top="tooltipPosition === 'top'">
        <template v-slot:activator="{ on }">
          <v-btn icon @click="open" v-on="on">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('about') }}</span>
      </v-tooltip>
    </template>
    <v-dialog v-model="show" max-width="600" @click:outside="close">
      <v-card>
        <CoreContentToolbar>
          {{ appName }} - V{{ appVersion }}
          <template slot="items-append">
            <v-btn small @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
          </template>
        </CoreContentToolbar>
        <v-divider />
        <CoreLogo :width="300" class="my-5 mx-auto" :logo="logo" v-if="logo" />
        <v-card-text class="text-justify" v-html="appDescription" />
      </v-card>
    </v-dialog>
  </div>
</template>

<i18n locale="fr">
{
  "about": "A propos"
}
</i18n>

<i18n locale="en">
{
  "about": "About"
}
</i18n>