<script>
import { DateTime } from 'luxon'
import CoreDatePicker from './CoreDatePicker.vue'
// import DateRangePicker from './DateRangePicker.vue'
import STACExplorerFiltersIdsAutocomplete from './STACExplorerFiltersIdsAutocomplete.vue'

export default {
  name: 'STACExplorerFilters',
  components: {
    CoreDatePicker,
    // DateRangePicker,
    STACExplorerFiltersIdsAutocomplete
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    collection: {
      // STACCollection object
      type: Object,
      required: true,
    },
    geometry: {
      type: Object,
    },
    orderBy: {
      type: String,
      default: '-properties.datetime',
    },
    advancedOrderBy: {
      type: Boolean,
      default: false,
    },
    wrappable: {
      type: Boolean,
      default: false,
    },
    wrapped: {
      type: Boolean,
      default: false,
    },
    datePickerType: {
      type: String,
      default: 'legacy',
    },
    dateRange: {
      type: Array,
      default: () => [null, null],
    },
    hideDatePicker: {
      type: Boolean,
      default: false
    },
    wrappableAdvancedFilter: {
      type: Boolean,
      default: true
    },
    hideSearchAndSort: {
      type: Boolean,
      default: false
    },
    disableIdImport: {
      type: Boolean,
      default: false
    },
    centerTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      datumStore: {},
      startDate: '',
      endDate: '',
      itemId: '',
      config: {},
      adFilter: false,
      dateFieldsValid: true,
      excludedMonth: [],
      listMonth: [
        { text: 'January', value: 1 },
        { text: 'February', value: 2 },
        { text: 'March', value: 3 },
        { text: 'April', value: 4 },
        { text: 'May', value: 5 },
        { text: 'June', value: 6 },
        { text: 'July', value: 7 },
        { text: 'August', value: 8 },
        { text: 'September', value: 9 },
        { text: 'October', value: 10 },
        { text: 'November', value: 11 },
        { text: 'December', value: 12 },
      ],
      descending: true,
      sortSelected: 'properties.datetime',
      pickerStartDate: null,
      pickerEndDate: null,
      filter: '.csv',
      idList: [],
      idSelected: [],
      defaultSortOpts: [
        { text: 'Name', value: 'id' },
        { text: 'Date', value: 'properties.datetime' }
      ]
    }
  },
  computed: {
    sortOpts() {
      const excludedOrderFields = this.collection.properties['ex:excluded_order_fields'] || []
      const sortOpts = [
        ...this.defaultSortOpts.filter(opt => !excludedOrderFields.includes(opt.value))
      ]

      if (this.advancedOrderBy) {
        for (const key of Object.keys(this.cleanSummaries)) {
          if (!excludedOrderFields.includes(`properties.${key}`)) sortOpts.push({ text: key, value: `properties.${key}` })
        }
      }
      return sortOpts
    },
    internalWrapped: {
      get() {
        return this.wrapped
      },
      set(v) {
        this.$emit('update:wrapped', v)
      },
    },
    internalDateRange: {
      get() {
        return this.dateRange
      },
      set(v) {
        this.$emit('update:dateRange', v)
      },
    },
    summaries() {
      return this.collection.summaries
    },
    rangeDate() {
      if (this.collection.extent?.temporal?.interval?.length)
        return this.collection.extent.temporal.interval[0]
      return null
    },
    minDate() {
      if (this.rangeDate?.length && this.rangeDate[0])
        return DateTime.fromISO(this.rangeDate[0]).toFormat('yyyy-MM-dd')
      return null
    },
    minEndDate() {
      if (this.minDate) {
        if (this.internalStartDate) {
          const start = DateTime.fromISO(this.internalStartDate)
          const min = DateTime.fromISO(this.minDate)
          if (start > min) return this.internalStartDate
          else return this.minDate
        } else return this.minDate
      }
      return null
    },
    maxDate() {
      if (this.rangeDate?.length && this.rangeDate[1]) return DateTime.fromISO(this.rangeDate[1]).toFormat('yyyy-MM-dd')
      return DateTime.now().plus({ year: 1 }).toFormat('yyyy-MM-dd')
    },
    maxStartDate() {
      if (this.maxDate) {
        if (this.internalEndDate) {
          const end = DateTime.fromISO(this.internalEndDate)
          const max = DateTime.fromISO(this.maxDate)
          if (end < max) return this.internalEndDate
          else return this.maxDate
        } else return this.maxDate
      }
      return null
    },
    internalStartDate: {
      get: function () {
        if (this.startDate) return DateTime.fromISO(this.startDate).toFormat('yyyy-MM-dd')
        return ''
      },
      set: function (v) {
        this.startDate = v
      },
    },
    internalEndDate: {
      get: function () {
        if (this.endDate)
          return DateTime.fromISO(this.endDate).toFormat('yyyy-MM-dd')
        return ''
      },
      set: function (v) {
        this.endDate = v
      },
    },
    cleanSummaries() {
      if (this.summaries) {
        return Object.keys(this.summaries).reduce((p, k) => {
          if (k !== 'created' && k !== 'updated') p[k] = this.summaries[k]
          return p
        }, {})
      }
      return {}
    },
    adFilterSummaries() {
      // FIXME: we could use entire cleanSummaries if we managed { "min", "max" } summaries for number properties
      const filterSummaries = Object.fromEntries(
        Object.entries(this.cleanSummaries).filter(
          ([_k,v]) => Array.isArray(v)
        )
      )

      return filterSummaries
    },
    disableExcludedMonth() {
      return !this.internalStartDate || !this.internalEndDate
    },
    internalOrderBy: {
      get: function () {
        return this.orderBy
      },
      set: function (v) {
        this.$emit('update:orderBy', v)
      },
    },
    internalExactCount: {
      get: function () {
        return this.exactCount
      },
      set: function (v) {
        this.$emit('update:exactCount', v)
      },
    },
    internalTotalCount: {
      get: function () {
        return this.totalCount
      },
      set: function (v) {
        this.$emit('update:totalCount', v)
      },
    },
  },
  watch: {
    geometry() {
      this.formatCriterias()
    },
    startDate() {
      this.formatCriterias()
    },
    endDate() {
      this.formatCriterias()
    },
    excludedMonth() {
      this.formatCriterias()
    },
    itemId() {
      this.formatCriterias()
    },
    idSelected() {
      this.formatCriterias()
    },
    config: {
      deep: true,
      handler() {
        this.formatCriterias()
      }
    },
    descending(v) {
      if (v) this.internalOrderBy = `-${this.sortSelected}`
      else this.internalOrderBy = `+${this.sortSelected}`
    },
    sortSelected(v) {
      if (v)
        this.internalOrderBy = `${this.descending ? '-' : '+'}${
          this.sortSelected
        }`
    },
    orderBy(v) {
      if (v) {
        const direction = v.charAt(0)
        if (direction === '-') this.descending = true
        else this.descending = false
        this.sortSelected = v.slice(1)
      }
    },
  },
  mounted() {
    this.config = {}
    if (this.orderBy) {
      const direction = this.orderBy.charAt(0)
      if (direction === '-') this.descending = true
      else this.descending = false
      this.sortSelected = this.orderBy.slice(1)
    }
    const orderExist = this.sortOpts.find(s => s.value === this.sortSelected)
    if (!orderExist) {
      this.sortSelected = this.sortOpts[0].value
      this.internalOrderBy = `${this.descending ? '-' : '+'}${this.sortSelected}`
    }
    this.initConfig()
    this.formatCriterias()
  },
  methods: {
    formatCriterias() {
      const criterias = {
        filter: this.formatFilter(),
      }

      let startDate = '..'
      let completionDate = '..'
      if (this.startDate)
        startDate = this.parseDate(
          this.startDate,
          "yyyy-MM-dd'T'HH:mm:ss'Z'",
          'start'
        )
      if (this.endDate)
        completionDate = this.parseDate(
          this.endDate,
          "yyyy-MM-dd'T'HH:mm:ss'Z'",
          'end'
        )
      if (this.excludedMonth.length) {
        const range = this.formatRanges(startDate, completionDate)
        if (range) criterias.datetimes = range
      } else criterias.datetime = `${startDate}/${completionDate}`

      if (this.itemId) criterias.idIncludes = this.itemId
      if (this.idSelected.length) criterias.ids = this.idSelected

      if (this.geometry) {
        const geom = { ...this.geometry }
        if (geom.__typename) delete geom.__typename
        criterias.intersects = geom
      }

      // this.searchCriterias = criterias

      // Emit criterias update
      this.$emit('input', criterias)
    },
    cleanLabel(value) {
      return value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' ')
    },
    parseDate(date, dateFormat = 'yyyy-MM-dd', type = false) {
      let d = DateTime.fromISO(date, { zone: 'UTC' }).toUTC()
      switch (type) {
        case 'start':
          d = d.startOf('day')
          break
        case 'end':
          d = d.endOf('day')
          break
        default:
          break
      }

      return d.toFormat(dateFormat)
    },
    formatRanges(startDate, completionDate) {
      if (startDate !== '..' && completionDate !== '..') {
        const start = DateTime.fromISO(startDate)
        const end = DateTime.fromISO(completionDate)
        const excludedMonth = this.excludedMonth.map((m) => m.value)

        const range = []
        let curDay = start
        let currentRange = []

        while (curDay < end) {
          if (currentRange.length === 0) {
            if (excludedMonth.includes(curDay.month)) {
              curDay = curDay.endOf('month').plus({ days: 1 }).startOf('day')
              continue
            }
            currentRange.push(
              curDay.startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'")
            )
          }
          let nextMonth = curDay.plus({ months: 1 })
          if (nextMonth >= end) nextMonth = end
          if (excludedMonth.includes(nextMonth.month)) {
            currentRange.push(
              curDay.endOf('month').toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'")
            )
            range.push(currentRange.join('/'))
            currentRange = []
            nextMonth = nextMonth
              .endOf('month')
              .plus({ days: 1 })
              .startOf('day')
          }

          curDay = nextMonth
        }
        if (currentRange.length === 1) {
          currentRange.push(
            this.parseDate(end, "yyyy-MM-dd'T'HH:mm:ss'Z'", 'end')
          )
          range.push(currentRange.join('/'))
          currentRange = []
        }
        return range
      }
      return null
    },
    formatFilter() {
      const productMetas = []
      for (const [key, value] of Object.entries(this.config)) {
        if (value.length) productMetas.push({ identifier: key, values: value })
      }
      const filterList = []
      for (const meta of productMetas) {
        filterList.push({
          in: [{ property: meta.identifier }, meta.values]
        })
      }
      let filter = {}
      if (filterList.length === 1) filter = filterList[0]
      else if (filterList.length > 1) filter.and = filterList
      else filter = {}
      return filter
    },
    initConfig() {
      this.config = {}
      if (Object.entries(this.cleanSummaries).length) {
        for (const [k, v] of Object.entries(this.cleanSummaries)) {
          if (v.length === 1) this.config[k] = v.map(va => va)
        }
      }
    },
    uploadFile() {
      if (this.$refs.inputListIds && !this.disableIdImport) this.$refs.inputListIds.click()
    },
    loadFile(e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        const file = files[0]
        if (file.name.lastIndexOf('.') <= 0) return

        const fr = new FileReader()
        if (file.name.split('.').pop() === 'csv') fr.readAsText(file)
        else {
          this.$notifier.error('Only csv file are allowed')
          return
        }

        fr.addEventListener('loadend', () => {
          this.updateCriterias(file.name, fr.result)
        })
      }
      // Allow the change event to be fired with the same file
      this.$refs.inputListIds.value = null
    },
    updateCriterias(filename, result) {
      const ext = filename.split('.').pop()
      if (ext === 'csv') {
        const array = result.split('\n')
        const _listIds = array.map(a => {
          const list = a.split(',')
          return list[0]
        })
        const listIds = _listIds.filter(l => l && l !== '' && l !== 'satellite_id')
        const a = [...this.idList]
        const b = [...this.idSelected]
        a.push(...listIds)
        b.push(...listIds)
        this.idList = [...new Set(a)]
        this.idSelected = [...new Set(b)]
      } else {
        this.$notifier.error('Only csv file are allowed')
      }
    }
  },
  created() {
    this.pickerStartDate = this.minDate
    this.pickerEndDate = DateTime.fromISO(this.maxDate) > DateTime.now() ? DateTime.now().toFormat('yyyy-MM-dd') : this.maxDate
  }
}
</script>

<template>
  <div ref="content" class="d-flex flex-column flex-grow-1">
    <v-subheader class="pa-2" :class="{ 'mx-auto': centerTitle }">
      <span
        :class="{ overline: true, 'cursor-pointer': wrappable }"
        style="font-size: 0.9rem !important"
        @click="wrappable ? (internalWrapped = !internalWrapped) : null"
      >
        Filters
      </span>
      <v-btn
        v-if="wrappable"
        small
        icon
        @click="internalWrapped = !internalWrapped"
      >
        <v-icon v-html="wrapped ? 'mdi-chevron-down' : 'mdi-chevron-up'" />
      </v-btn>
      <slot name="header-append" :criterias="value" />
    </v-subheader>
    <v-expand-transition>
      <div v-if="!wrappable || !wrapped" class="d-flex flex-column pa-2">
        <template v-if="!hideDatePicker">
          <div v-if="datePickerType === 'test'" class="d-flex">
            <!-- <DateRangePicker
              v-model="internalDateRange"
              :range="[minDate, maxDate]"
            /> -->
          </div>
          <!-- <div class="px-4 d-flex flex-row pb-4"> -->
          <v-row no-gutters v-else>
            <v-col cols="4">
              <CoreDatePicker
                v-model="internalStartDate"
                :min-date="minDate"
                :max-date="maxStartDate"
                label="Start date"
                transition="slide-y-reverse-transition"
                only-date
                clearable
                prepend-inner-icon="mdi-calendar"
                :append-icon="null"
                :top="false"
                hide-details
                :picker-date.sync="pickerStartDate"
              />
            </v-col>
            <v-col cols="4">
              <CoreDatePicker
                v-model="internalEndDate"
                class="ml-2"
                :min-date="minEndDate"
                :max-date="maxDate"
                label="End date"
                transition="slide-y-reverse-transition"
                only-date
                clearable
                prepend-inner-icon="mdi-calendar"
                :append-icon="null"
                :top="false"
                hide-details
                :picker-date.sync="pickerEndDate"
              />
            </v-col>
            <v-col cols="4">
              <v-combobox
                v-model="excludedMonth"
                class="ml-2"
                :items="listMonth"
                label="Excluded months"
                multiple
                outlined
                dense
                clearable
                :disabled="disableExcludedMonth"
                hide-details
              />
            </v-col>
          </v-row>
        </template>
        <!-- </div> -->
        <template v-if="Object.entries(adFilterSummaries).length">
          <v-subheader class="ml-1" style="font-size: 0.9rem !important" v-if="wrappableAdvancedFilter">
            <a @click="adFilter = !adFilter">
              <span class="text-link">Advanced filters</span>
              <v-icon v-if="adFilter" color="primary" class="ml-1" dense>mdi-chevron-up</v-icon>
              <v-icon v-else color="primary" class="ml-1" dense>mdi-chevron-down</v-icon>
            </a>
          </v-subheader>
          <v-expand-transition>
            <div v-if="adFilter || !wrappableAdvancedFilter" class="d-flex flex-column">
              <v-row dense>
                <v-col
                  v-for="[k, v] of Object.entries(adFilterSummaries)"
                  :key="k"
                  cols="4"
                  class="py-0"
                >
                  <v-select
                    v-model="config[k]"
                    :items="v"
                    outlined
                    dense
                    menu-props="offset-y"
                    :label="k"
                    multiple
                    :disabled="v.length === 1"
                  />
                </v-col>
              </v-row>
              <v-row dense v-if="!disableIdImport">
                <v-col cols="12">
                  <STACExplorerFiltersIdsAutocomplete :ids.sync="idList" v-model="idSelected" />
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </template>
      </div>
    </v-expand-transition>
    <v-divider v-if="!hideSearchAndSort" />
    <div class="d-flex flex-row align-center pl-2 py-2" v-if="!hideSearchAndSort">
      <v-text-field
        v-model="itemId"
        outlined
        dense
        clearable
        hide-details
        label="Search"
        prepend-inner-icon="mdi-magnify"
        class="mr-5"   
      >
        <template v-slot:append>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon v-if="!disableIdImport" v-on="on" @click="uploadFile">mdi-file-plus-outline</v-icon>
            </template>
            <span>Import a csv file containing a list of identifiers</span>
          </v-tooltip>
        </template>
      </v-text-field>
      <input type="file" ref="inputListIds" class="d-none" :accept="filter" @change="loadFile" v-if="!disableIdImport" />
      <v-select
        v-model="sortSelected"
        :items="sortOpts"
        outlined
        dense
        style="max-width: 125px"
        hide-details
        label="Sort by"
      />
      <v-tooltip bottom>
        <template #activator="{ on }">
          <div v-on="on">
            <v-btn icon @click="descending = !descending">
              <v-icon v-if="descending"> mdi-arrow-down-thin </v-icon>
              <v-icon v-else> mdi-arrow-up-thin </v-icon>
            </v-btn>
          </div>
        </template>
        <span v-if="descending">Descending</span>
        <span v-else>Ascending</span>
      </v-tooltip>
    </div>
    <slot :criterias="value" />
  </div>
</template>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.text-link {
  cursor: pointer;
  color: var(--v-primary-base);
}
.text-link:hover {
  text-decoration: underline;
}
</style>
