<script>
import { LOCAL_DEAUTHENTICATE } from '../store/auth/mutations'

export default {
  name: 'CoreLogout',
  props: {
    icon: {
      type: String,
      default: 'mdi-power'
    },
    tooltipPosition: {
      type: String,
      default: 'bottom'
    },
    plainText: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async deauthenticate() {
      await this.$apollo.mutate({
        mutation: LOCAL_DEAUTHENTICATE
      })
      await this.$router.push({ name: 'login' })
      // Reset local state and store cache
      // this.$apollo.provider.defaultClient.cache.reset()
      // this.$apollo.provider.defaultClient.resetStore()
      // window.location.href = window.location.href
      // document.location.reload(true)
      // Alternative to prevent in flight request error (doesn't trigger onResetStore hooks)
    }
  }
}
</script>

<template>
  <v-list-item @click="deauthenticate" v-if="plainText">
    <v-list-item-icon><v-icon>{{ icon }}</v-icon></v-list-item-icon>
    <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
  </v-list-item>
  <v-tooltip :bottom="tooltipPosition === 'bottom'" :left="tooltipPosition === 'left'" :right="tooltipPosition === 'right'" :top="tooltipPosition === 'top'" v-else>
    <template v-slot:activator="{ on }">
      <v-btn icon @click="deauthenticate" v-on="on">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('logout') }}</span>
  </v-tooltip>
</template>

<i18n locale="fr">
{
  "logout": "Deconnexion"
}
</i18n>

<i18n locale="en">
{
  "logout": "Logout"
}
</i18n>