<script>
import { CoreContentToolbar } from '@exwexs/vue-core'

import USERS_QUERY from '@/graphql/query/user/Users.gql'
import USER_CREATED_SUBSCRIPTION from '@/graphql/subscription/user/userCreated.gql'
import USER_UPDATED_SUBSCRIPTION from '@/graphql/subscription/user/userUpdated.gql'
import USER_DELETED_SUBSCRIPTION from '@/graphql/subscription/user/userDeleted.gql'
import UNIVERSE_CONFIG_CREATE_MUTATION from '@/graphql/mutation/user/UniverseConfigCreate.gql'
import UNIVERSE_CONFIG_UPDATE_MUTATION from '@/graphql/mutation/user/UniverseConfigUpdate.gql'

export default {
  name: 'AddEditUniverseConfig',
  components: {
    CoreContentToolbar
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    universeConfigEdited: {
      type: Object,
      default: () => {
        return null
      }
    },
    title: {
      type: String,
      default: 'New Universe Configuration'
    }
  },
  data() {
    return {
      users: [],
      valid: true,
      modulesList: [
        { name: '', order: '0', logoColor: '#000000', disabled: false, colorMenu: false }
      ],
      appEnum: [
        { name: 'pathfinder', color: '#8e24aa' },
        { name: 'asyst', color: '#64B5F6' },
        { name: 'datum', color: '#1976D2' },
        { name: 'moonseed', color: '#4caf50' },
        { name: 'viz', color: '#90a4ae' }
      ],
      userIds: [],
      rules: {
        required: v => !!v || 'Required.',
        number: v => /^[0-9]+$/.test(v) || 'Must be a number'
      }
    }
  },
  apollo: {
    users: {
      query: USERS_QUERY,
      variables() {
        return {
          pagination: { page: 0, size: 50 }
        }
      }
    },
    $subscribe: {
      userCreated: {
        query: USER_CREATED_SUBSCRIPTION,
        result({ data }) {
          if (data?.userCreated?.id) {
            this.$apollo.queries.users.refetch()
          }
        }
      },
      userUpdated: {
        query: USER_UPDATED_SUBSCRIPTION,
        result({ data }) {
          if (data?.userUpdated?.id) {
            this.$apollo.queries.users.refetch()
          }
        }
      },
      userDeleted: {
        query: USER_DELETED_SUBSCRIPTION,
        result() {
          this.$apollo.queries.users.refetch()
        }
      }
    }
  },
  methods: {
    addModule() {
      this.modulesList.push({ name: '', order: '0', logoColor: '#000000', disabled: false, colorMenu: false })
    },
    removeModule(i) {
      this.modulesList.splice(i, 1)
    },
    changeColor(i) {
      const name = this.modulesList[i].name
      const obj = this.appEnum.find(a => a.name === name)
      this.modulesList[i].logoColor = obj.color
    },
    closeUniverseConfig() {
      this.modulesList = [{ name: '', order: '0', logoColor: '#000000', disabled: false, colorMenu: false }]
      this.userIds = []
      this.$emit('close')
    },
    checkDuplicate() {
      const modArr = this.modulesList.map(m => m.name)
      return modArr.some((m, i) => {
        return modArr.indexOf(m) !== i
      })      
    },
    async saveUniverseConfig() {
      if (this.$refs.form.validate()) {
        if (this.checkDuplicate()) {
          this.$notifier.error('You can\'t duplicate app')
          return
        }
        const configToSend = {
          userIds: this.userIds,
          modules: this.modulesList.map(m => {
            return {
              name: m.name,
              order: parseInt(m.order),
              logoColor: m.logoColor,
              disabled: m.disabled
            }
          })
        }
        if (this.universeConfigEdited) {
          try {
            const res = await this.$apollo.mutate({
              mutation: UNIVERSE_CONFIG_UPDATE_MUTATION,
              variables: {
                id: this.universeConfigEdited.id,
                modifications: { ...configToSend }
              }
            })
            const { data, errors } = res
            if (data.updateUniverseConfig.id) {
              this.closeUniverseConfig()
            } else {
              this.$notifier.error(errors)
            }
          } catch (err) {
            this.$notifier.error(err.message.replace('GraphQL error: ', ''))
            throw err
          }
        } else {
          try {
            const res = await this.$apollo.mutate({
              mutation: UNIVERSE_CONFIG_CREATE_MUTATION,
              variables: {
                inputs: { ...configToSend }
              }
            })
            const { data, errors } = res
            if (data.createUniverseConfig.id) {
              this.closeUniverseConfig()
            } else {
              this.$notifier.error(errors)
            }
          } catch (err) {
            this.$notifier.error(err.message.replace('GraphQL error: ', ''))
            throw err
          }
        }
      }
    },
    sortModule(a, b) {
      if (parseInt(a.order) < parseInt(b.order)) return -1
      if (parseInt(a.order) > parseInt(b.order)) return 1
      return 0
    }
  },
  computed: {
    internalShow: {
      get() {
        return this.show
      },
      set (v) {
        this.$emit('update:show', v)
      }
    },
    orderedModules() {
      const om = [...this.modulesList]
      return om.sort(this.sortModule)
    }
  },
  watch: {
    universeConfigEdited(newValue) {
      if (newValue) {
        this.userIds = newValue.users.map(u => u.id)
        this.modulesList = newValue.modules.map(m => {
          return {
            name: m.name,
            order: m.order,
            logoColor: m.logoColor,
            disabled: m.disabled,
            colorMenu: false
          }
        })
      } else {
        this.modulesList = [{ name: '', order: '0', logoColor: '#000000', disabled: false, colorMenu: false }]
        this.userIds = []
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="internalShow" max-width="800px" persistent>
    <v-card>
      <CoreContentToolbar :title="title" />
      <v-divider />
      <v-form ref="form" v-model="valid" @submit.prevent="saveUniverseConfig">
        <v-card-text>
          <v-row dense>
            <v-col cols="6">
              <v-select dense outlined multiple v-model="userIds" :items="users" item-text="username" item-value="id" label="Users" menu-props="offset-y" />
            </v-col>
            <v-col cols="5" offset="1" style="line-height: 36px">
              Preview
              <v-icon v-for="(mod, i) of orderedModules" :color="mod.logoColor" :key="i" :disabled="mod.disabled">{{ `mdi-alpha-${mod.name.charAt(0)}-box` }}</v-icon>
            </v-col>
          </v-row>
          <v-row dense v-for="(mod, i) of modulesList" :key="i">
            <v-col cols="3">
              <v-select dense outlined v-model="mod.name" :items="appEnum" item-text="name" item-value="name" label="App Name" menu-props="offset-y" @change="changeColor(i)" />
            </v-col>
            <v-col cols="2">
              <v-text-field dense outlined label="Order" v-model="mod.order" :rules="[rules.number]" required />
            </v-col>
            <v-col cols="3">
              <v-text-field dense outlined label="Logo Color" v-model="mod.logoColor" :rules="[rules.required]" required hide-details>
                <template v-slot:append>
                  <v-menu v-model="mod.colorMenu" top nudge-bottom="100" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div :style="{ backgroundColor: mod.logoColor, cursor: 'pointer', height: '26px', width: '26px', borderRadius: mod.colorMenu ? '50%' : '4px', transition: 'border-radius 200ms ease-in-out' }" v-on="on"></div>
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="mod.logoColor" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-checkbox class="mt-1" v-model="mod.disabled" label="Disabled" />
            </v-col>
            <v-col cols="1" class="text-no-wrap ml-5">
              <v-tooltip bottom v-if="modulesList.length > 1">
                <template v-slot:activator="{ on }">
                  <v-btn color="error" @click="removeModule(i)" icon class="mt-1" v-on="on"><v-icon>mdi-delete-circle-outline</v-icon></v-btn>
                </template>
                <span>Remove module</span>
              </v-tooltip>
              <v-tooltip bottom v-if="i === modulesList.length - 1">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" @click="addModule" icon class="mt-1" v-on="on"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                </template>
                <span>Add a module</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn outlined color="red darken-2" @click="closeUniverseConfig">Cancel</v-btn>
          <v-btn outlined color="green darken-2" :disabled="!valid" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>