<script>
import CoreBottomContainer from '../components/CoreBottomContainer'

export default {
  name: 'CoreSubLayout',
  components: {
    CoreBottomContainer
  },
  props: {
    leftPanel: {
      type: Boolean,
      default: true
    },
    leftPanelMinWidth: {
      type: String,
      default: '30vw'
    },
    rightPanel: {
      type: Boolean,
      default: false
    },
    rightPanelMinWidth: {
      type: String,
      default: '30vw'
    },
    bottomPanel: {
      type: Boolean,
      default: false
    },
    bottomPanelMinHeight: {
      type: Number,
      default: 50
    },
    bottomPanelShowHidingButton: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<template>
  <v-col style="display: flex; flex-direction: column; height: 100%; padding: 0 !important">
    <v-row no-gutters style="flex: 1; overflow: hidden">
      <!-- Left panel-->
      <v-col class="flex-grow-0 flex-shrink-1" style="height: 100%" v-if="leftPanel">
        <div 
          :style="{
            'min-width': leftPanelMinWidth,
            'height': '100%',
            'border-right': '1px solid',
            'border-color': $vuetify.theme.dark ? '#424242' : '#e0e0e0'
          }"
        >
          <v-navigation-drawer permanent stateless :min-width="leftPanelMinWidth" style="width: 100%; height: 100%">
            <slot name="left-content"></slot>
          </v-navigation-drawer>
        </div>
      </v-col>
     <!-- Center panel -->
      <v-col>
        <v-row no-gutters class="fill-height">
          <slot></slot>
        </v-row>
      </v-col>
      <!-- Right panel-->
      <v-col class="flex-grow-0 flex-shrink-1" style="height: 100%" v-if="rightPanel">
        <div 
          :style="{
            'min-width': rightPanelMinWidth,
            'height': '100%',
            'border-right': '1px solid',
            'border-color': $vuetify.theme.dark ? '#424242' : '#e0e0e0'
          }"
        >
          <v-navigation-drawer permanent stateless :min-width="rightPanelMinWidth" style="width: 100%; height: 100%">
            <slot name="right-content"></slot>
          </v-navigation-drawer>
        </div>
      </v-col>
    </v-row>
    <CoreBottomContainer :minHeight="bottomPanelMinHeight" :showHidingButton="bottomPanelShowHidingButton" v-if="bottomPanel">
      <slot name="bottom-content"></slot>
    </CoreBottomContainer>
  </v-col>
</template>