<script>
export default {
  name: 'XTooltip',
  props: {
    bottom: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <v-tooltip :bottom="bottom" :top="top" :left="left" :right="right">
    <template v-slot:activator="{ on, attrs}">
      <slot :on="on" :attrs="attrs"></slot>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>