import { render, staticRenderFns } from "./TableGeneric.vue?vue&type=template&id=5e5b6a68&"
import script from "./TableGeneric.vue?vue&type=script&lang=js&"
export * from "./TableGeneric.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_fda74d0dd233d5440f16000c160c219e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_be6ad1899880d627e43945eca176d74c/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardText,VContainer,VDataTable,VIcon,VProgressCircular,VTooltip})
