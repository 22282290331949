<script>
export default {
  name: 'XIcon',
  props: {
    color: String,
    disabled: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <v-icon :color="color" :disabled="disabled" :large="large" :small="small" :x-large="xLarge" :x-small="xSmall">
    <slot></slot>
  </v-icon>
</template>
