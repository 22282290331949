<script>
  import Alert from './CoreNotifierAlert'
  
  export default {
    name: 'CoreNotifier',
    props: {
      notifications: { type: Array, required: true },
      timeout: { type: Number, default: 4000 }
    },
    components: {
      Alert
    },
    data () {
      return {
        stack: []
      }
    },
    watch: {
      notifications(notifications) {
        this.addMessage(notifications[0])
      }
    },
    mounted () {
      if (this.notifications.length) {
        this.addMessage(this.notifications[0])
      }
    },
    methods: {
      addMessage (_message) {
        const message = Object.assign({ opacity: 1 }, _message)
        this.stack.push(message)
        setTimeout(this.removeMessage, this.timeout, _message)
      },
      removeMessage(_message) {
        this.stack = this.stack.filter(m => m.date !== _message.date)
      }
    }
  }
</script>

<template>
  <div class="cc-notifier">
    <transition-group name="alert" tag="div">
      <alert v-for="message of stack" :key="message.date" :type="message.type" :style="{ opacity: message.opacity }" @close="removeMessage(message)">
        <span>{{ message.text }}</span>
        <span slot="details" v-if="message.details">{{ message.details }}</span>
      </alert>
    </transition-group>
  </div>
</template>

<style lang="sass">
  .cc-notifier
    position: fixed
    top: 0
    right: 80px
    z-index: 10000
    display: flex
    flex-direction: column-reverse
    align-items: flex-end
    overflow-y: visible
    & > div
      min-width: 400px
      margin: 4px 0
    .alert-enter-active
      transition: opacity 1s
    .alert-leave-active
      transition: opacity 1s
  
  .cc-notifier .alert-enter, .cc-notifier .alert-leave-to
    opacity: 0 !important
</style>
