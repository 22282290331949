<script>
import combinePagination from 'combine-pagination'
import makeCancelable from '../utils/makeCancelable'
import STACExplorerItem from './STACExplorerItem.vue'

import STAC_ITEMS_QUERY from '../graphql/query/STACItemsNoInfo.gql'
import STAC_ITEMS_ADD_DATA_QUERY from '../graphql/query/STACItemsAddData.gql'
import STAC_ITEMS_SUBSCRIPTION from '../graphql/subscription/STACItem.gql'


export default {
  name: 'STACExplorerFetcher',
  components: {
    STACExplorerItem
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    collection: {
      type: String,
      required: true
    },
    criterias: {
      type: Object,
      default: () => ({})
    },
    selectedFeature: { // For single select
      type: Object,
    },
    selectedFeatures: { // For multiselect
      type: Array,
      default: () => []
    },
    targetCollection: String,
    orderBy: {
      type: String,
      default: '-properties.datetime'
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    // Requires multiselect == false, select first item available and prevent item deselect
    mandatory: {
      type: Boolean,
      default: false
    },
    virtualScroll: {
      type: Boolean,
      default: false
    },
    virtualScrollBench: {
      type: Number,
      default: 2
    },
    pageSize: {
      type: Number,
      default: 200
    },
    loaderType: {
      type: String,
      default: 'skeleton'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    $subscribe: {
      STACItem: {
        query: STAC_ITEMS_SUBSCRIPTION,
        variables() {
          return {
            types: ['CREATED', 'UPDATED', 'DELETED'],
            criterias: {
              collection: this.collection
            }
          }
        },
        result({ data }) {
          if (data?.STACItem) {
            const { type, entity, entities } = data.STACItem
            switch (type) {
              case 'CREATED':
                // FIXME: invalid id subscription (bad collection)
                // handle entity
                if (entities?.length) {
                  const items = [...this.internalValue]
                  items.unshift(...entities)
                  this.internalValue = [...new Set(items)]
                  this.$emit('created', entities)
                }
                break
              case 'UPDATED': {
                const itemIndex = this.internalValue.findIndex(i => i.id === entity.id)
                if (itemIndex > -1) this.internalValue[itemIndex] = entity
                this.$emit('updated', entity)
                break
              }
              case 'DELETED': {
                for (const ent of entities) {
                  const itemIndex = this.internalValue.findIndex(i => i.id === ent.id)
                  if (itemIndex > -1) this.internalValue.splice(itemIndex, 1)
                }
                this.$emit('deleted', entities)
                break
              }
              default:
                console.error('Unknown subscription type : ' + type)
            }

            if (this.internalValue.length) {
              this.$nextTick(() => this.bindItemObserver())
            }
          }
        }
      }
    }
  },
  data() {
    return {
      results: [],
      pendingQuery: null,
      combinedGetters: null,
      height: 100,
      itemHeight: 67,
      observerOffset: 5
    }
  },
  computed: {
    internalLoading: {
      get() {
        return this.loading
      },
      set(v) {
        this.$emit('update:loading', v)
      }
    },
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    internalSelectAll: {
      get() {
        return this.selectAll
      },
      set(v) {
        this.$emit('update:selectAll', v)
      }
    },
    internalSelectedFeatures: {
      get() {
        return this.selectedFeatures
      },
      set(v) {
        this.$emit('update:selectedFeatures', v)
      }
    },
    displayedProperty () {
      const orderProp = this.orderBy.slice(1)
      if (orderProp === 'id') {
        return ''
      }
      return orderProp
    }
  },
  watch: {
    collection() {
      this.initCombined()
    },
    criterias() {
      this.initCombined()
    },
    targetCollection() {
      this.initCombined()
    },
    orderBy(newValue, oldValue) {
      if (newValue !== oldValue) {
      this.initCombined()
      }
    },
    selectAll(v) {
      if (v) this.internalSelectedFeatures = []
    },
    selectedFeature(v) {
      if (v && Object.keys(v).length && !this.isInViewport(v)) {
        try {
          this.scrollTo(v)
        } catch (err) {
          // Catch initial error when virtualScroll is not mounted yet
          if (err.message !== 'Failed to scroll to feature, container is not mounted yet or virtualScroll is not enabled') { // FIXME: err code
            throw err
          }
        } 
      }
    }
  },
  created() {
    // Init combined pagination module
    this.initCombined()
  },
  mounted() {
    // Init height for virtual scroll
    if (this.virtualScroll) {
      this.resizeObserver = new ResizeObserver(() => {
        this.refreshHeight()
      })
      this.resizeObserver.observe(this.$refs.content)
    }
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
    if (this.itemResizeObserver) {
      this.itemResizeObserver.disconnect()
    }
  },
  methods: {
    bindItemObserver() {
      if (!this.$refs.item) {
        throw new Error('Item not available in DOM')
      }
      if (this.itemResizeObserver) {
        this.itemResizeObserver.disconnect()
      }
      this.itemResizeObserver = new ResizeObserver(() => {
        this.refreshItemHeight()
      })
      this.itemResizeObserver.observe(this.$refs.item)
    },
    refreshItemHeight() {
      const height = this.$refs.item?.getBoundingClientRect().height
      if (height) {
        this.itemHeight = height
      }
    },
    refreshHeight() {
      const height = this.$refs.content?.getBoundingClientRect().height
      if (height) {
        this.height = height
      }
    },
    toggleFeature(feature) {
      if (!this.multiselect) {
        if (this.selectedFeature?.id === feature.id && !this.mandatory) {
          this.$emit('update:selectedFeature', {})
        } else {
            this.$emit('update:selectedFeature', feature)
        }
      } else if (!this.selectAll) {
        const index = this.internalSelectedFeatures.findIndex(f => f.id === feature.id)
        const feats = [...this.internalSelectedFeatures]
        if (index === -1) feats.push(feature)
        else feats.splice(index, 1)
        this.internalSelectedFeatures = feats
      }
    },
    async fetchMore(entries, observer, isIntersecting) {
      if (isIntersecting && !this.loading) {
        await this.getFeatures()
      }
    },
    async getItems(page, range) {
      // eslint-disable-next-line no-unused-vars
      const { datetimes, ...crits } = this.criterias
      const criterias = {
        ...crits,
        collection: this.collection
      }
      if (range) criterias.datetime = range
      try {
        const obj = {
          variables: {
            criterias,
            pagination: {
              page: page + 1,
              size: this.pageSize
            },
            order: [this.orderBy],
          },
          fetchPolicy: 'no-cache'
        }
        if (this.multiselect) {
          obj.query = STAC_ITEMS_ADD_DATA_QUERY
          obj.variables.inCollection = this.targetCollection
        } else {
          obj.query = STAC_ITEMS_QUERY
        }

        const res = await this.$apollo.query(obj)
        return res?.data?.STACItems || []
      } catch (err) {
        console.error(err)
        return []
      }
    },
    async initCombined() {
      this.internalValue = []
      if (this.pendingQuery) {
        this.pendingQuery.cancel()
      }

      let getters = []
      if (this.criterias.datetimes) {
        const rangeDate = this.criterias.datetimes
        getters = rangeDate.map(range => page => this.getItems(page, range))
      } else {
        getters = [page => this.getItems(page, this.criterias.datetime)]
      }
      this.combinedGetters = combinePagination({
        getters,
        sort: (a,b) => {
          const sortOrder = this.orderBy.slice(0, 1)
          const sortProperty = this.orderBy.slice(1)
          let aProperty = a
          let bProperty = b
          for (const subProperty of sortProperty.split('.')) {
            aProperty = aProperty[subProperty]
            bProperty = bProperty[subProperty]
          }
          if (aProperty > bProperty) return sortOrder === '-' ? -1 : 1
          if (aProperty < bProperty) return sortOrder === '-' ? 1 : -1

          return 0
        }
      })
      await this.getFeatures()
    },
    async getFeatures () {
      this.internalLoading = true

      this.pendingQuery = makeCancelable(
        this.combinedGetters.getNext()
      )

      try {
        const res = await this.pendingQuery

        if (!this.internalValue.length && this.mandatory) {
          this.$emit('update:selectedFeature', res[0])
        }

        this.internalValue.push(...res)
        if (this.internalValue.length) {
          this.$nextTick(() => this.bindItemObserver())
        }

        if (res.length < this.pageSize) this.exhausted = true
        this.internalLoading = false
      } catch (err) {
        if (!err.canceled) {
          console.error(err)
          this.internalLoading = false
        }
      }
    },
    featureIsSelected(feature) {
      return this.selectAll
        || this.selectedFeature && this.selectedFeature.id === feature.id
        || this.internalSelectedFeatures.findIndex(f => f.id === feature.id) > -1
    },
    featureIsLocked(feature) {
      return this.selectAll || feature.isInCollection
    },
    scrollTo (feature) {
      const container = this.$refs.virtualScroll?.$el
      if (!container) {
        // FIXME: implement scrollTo for virtualScroll === false
        throw new Error('Failed to scroll to feature, container is not mounted yet or virtualScroll is not enabled')
      }

      const itemIndex = this.value.findIndex(f => f.id === feature.id)
      if (itemIndex < 0) {
        throw new Error('Failed to scroll to feature, not found in current features')
      }
      // FIXME: why -1 is required here ? (if not applied, scrollTo offsets more and more as we scroll down the list)
      const scrollTop = (this.itemHeight - 1) * itemIndex
      container.scrollTo({
        top: scrollTop,
        left: 0,
        behavior: 'smooth'
      })
    },
    isInViewport (feature) {
      // FIXME: implement scrollTo for virtualScroll === false
      const container = this.$refs.virtualScroll?.$el
      if (!container) {
        return false
      }

      const itemIndex = this.value.findIndex(f => f.id === feature.id)
      if (itemIndex < 0) {
        return false
      }

      const scrollTop = this.itemHeight * itemIndex
      const rect = container.getBoundingClientRect()
      const itemInViewport = Math.round(rect.height / this.itemHeight)
      const minHeight = container.scrollTop
      const maxHeight = itemInViewport * this.itemHeight + minHeight
      const isIn = scrollTop + this.itemHeight <= maxHeight && scrollTop >= minHeight

      return isIn
      // console.log('**********')
      // console.log('rect.height', rect.height)
      // console.log('index', itemIndex)
      // console.log('container.scrollTop', container.scrollTop)
      // console.log('scrollTop', scrollTop)
      // console.log('itemInViewport', itemInViewport)
      // console.log('maxHeight', maxHeight)
      // console.log('minHeight', minHeight)
      // console.log('isIn', isIn)
    }
  }
}
</script>

<template>
  <div
    ref="content"
    class="overflow-y-auto flex-grow-1"
  >
    <v-layout
      v-if="internalLoading && !internalValue.length"
      justify-center
      class="py-15"
    >
      <v-progress-circular
        indeterminate
        :size="50"
        color="primary"
        class="px-auto"
      />
    </v-layout>
    <template v-else-if="internalValue.length">
      <v-virtual-scroll
        ref="virtualScroll"
        v-if="virtualScroll"
        :bench="virtualScrollBench"
        :items="internalValue"
        :item-height="itemHeight"
        :height="height"
      >
        <template #default="{ item: feature, index }">

          <div ref="item">

            <slot
              :feature="feature"
              :index="index"
              :loading="internalLoading"
              :results="internalValue"
              name="item"
            >
              <STACExplorerItem
                :key="feature.id"
                :feature="feature"
                :multiselect="multiselect"
                :selected="featureIsSelected(feature)"
                :locked="featureIsLocked(feature)"
                :displayedProperty="displayedProperty"
                @toggle="toggleFeature(feature)"
              />
              <slot
                name="item-append"
                :feature="feature"
                :index="index"
                :loading="internalLoading"
              />
              <v-divider />
            </slot>
          </div>
          <div
            v-if="combinedGetters && index > (internalValue.length - observerOffset)"
            v-intersect="fetchMore"
          />
          <slot name="loader">
            <v-layout
              v-if="internalLoading && index === internalValue.length - 1"
              justify-center
              class="mt-5 mb-15"
            >
              <v-progress-circular
                indeterminate
                :size="50"
                color="primary"
                class="px-auto"
              />
            </v-layout>
          </slot>
        </template>
      </v-virtual-scroll>
      <!-- FIXME: code duplicate -->
      <!-- <v-lazy  v-else> -->
      <template
        v-for="(feature, index) in internalValue"
        v-else
      >
        <slot
          name="item"
          :feature="feature"
          :index="index"
          :loading="internalLoading"
          :results="internalValue"
          :selected="featureIsSelected(feature)"
          :locked="featureIsLocked(feature)"
          :toggleFeature="toggleFeature"
        >
          <STACExplorerItem
            :key="feature.id"
            :feature="feature"
            :multiselect="multiselect"
            :selected="featureIsSelected(feature)"
            :locked="featureIsLocked(feature)"
            :displayedProperty="displayedProperty"
            @select="toggleFeature(feature)"
          />
            <!-- :class="{ 'pa-4': !multiselect }" -->
          <slot
            name="item-append"
            :feature="feature"
            :index="index"
            :loading="internalLoading"
          />
          <v-divider :key="`separator_${index}`" />
        </slot>
        <div
          v-if="combinedGetters && index > (internalValue.length - observerOffset)"
          :key="`sensor_${index}`"
          v-intersect="fetchMore"
        />
        <slot name="loader">
          <template v-if="loaderType === 'skeleton'">
            <v-skeleton-loader />
          </template>
          <template v-else>
            <v-layout
              v-if="internalLoading && index === internalValue.length - 1"
              justify-center
              class="mt-5 mb-15"
            >
              <v-progress-circular
                indeterminate
                :size="50"
                color="primary"
                class="px-auto"
              />
            </v-layout>
          </template>
        </slot>
      </template>
    <!-- </v-lazy> -->
    </template>
    <slot
      v-else
      name="nodata"
    >
      <div class="pa-5">
        No product found
      </div>
    </slot>
  </div>
</template>
