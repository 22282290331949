import Vue from 'vue'
import makeRouter from './makeRouter'
import makeVuetify from './makeVuetify'
import makeInterceptor from './makeInterceptor'
import makeApolloProvider from './makeApollo'
import Notifier from './plugins/notifier'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
if (process.env.NODE_ENV !== 'production') console.log('vue-core: devtools enabled')

const makeVueCore = (opts = {}) => {
  if (!opts.apollo) opts.apollo = {}
  if (!opts.vuetify) opts.vuetify = {}
  if (!opts.routes) opts.routes = []

  const apolloProvider = makeApolloProvider({
    httpEndpoint: opts.apollo.httpEndpoint,
    wsEndpoint: opts.apollo.wsEndpoint,
    fragmentMatcher: opts.apollo.fragmentMatcher,
    dataIdFromObject: opts.apollo.dataIdFromObject,
    store: opts.apollo.store || []
  })

  const router = makeRouter({
    apolloProvider,
    routes: opts.routes,
    routerMode: opts.routerMode
  })

  Vue.use(Notifier)

  const unregisterInterceptor = makeInterceptor({ apolloProvider, router, notifier: Vue.prototype.$notifier })

  const vuetify = makeVuetify({ ...opts.vuetify })

  return {
    router,
    vuetify,
    apolloProvider,
    unregisterInterceptor
  }
}

export default makeVueCore
