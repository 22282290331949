var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticStyle:{"display":"flex","flex-direction":"column","height":"100%","padding":"0 !important"}},[_c('v-row',{staticStyle:{"flex":"1","overflow":"hidden"},attrs:{"no-gutters":""}},[(_vm.leftPanel)?_c('v-col',{staticClass:"flex-grow-0 flex-shrink-1",staticStyle:{"height":"100%"}},[_c('div',{style:({
          'min-width': _vm.leftPanelMinWidth,
          'height': '100%',
          'border-right': '1px solid',
          'border-color': _vm.$vuetify.theme.dark ? '#424242' : '#e0e0e0'
        })},[_c('v-navigation-drawer',{staticStyle:{"width":"100%","height":"100%"},attrs:{"permanent":"","stateless":"","min-width":_vm.leftPanelMinWidth}},[_vm._t("left-content")],2)],1)]):_vm._e(),_c('v-col',[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_vm._t("default")],2)],1),(_vm.rightPanel)?_c('v-col',{staticClass:"flex-grow-0 flex-shrink-1",staticStyle:{"height":"100%"}},[_c('div',{style:({
          'min-width': _vm.rightPanelMinWidth,
          'height': '100%',
          'border-right': '1px solid',
          'border-color': _vm.$vuetify.theme.dark ? '#424242' : '#e0e0e0'
        })},[_c('v-navigation-drawer',{staticStyle:{"width":"100%","height":"100%"},attrs:{"permanent":"","stateless":"","min-width":_vm.rightPanelMinWidth}},[_vm._t("right-content")],2)],1)]):_vm._e()],1),(_vm.bottomPanel)?_c('CoreBottomContainer',{attrs:{"minHeight":_vm.bottomPanelMinHeight,"showHidingButton":_vm.bottomPanelShowHidingButton}},[_vm._t("bottom-content")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }