<script>
export default {
  name: 'Unauthorized'
}
</script>

<template>
  <div class="stars">
    <div class="custom-navbar">
      <div class="brand-logo">
        <img :src="require('../assets/logo_exwexs_white.png')" width="200px">
      </div>
    </div>
    <div class="central-body">
      <div class="text-403">403</div>
      <div class="text-lost mt-n8">Looks like you are</div>
      <div class="text-lost mt-n2">lost in space</div>
    </div>
    <div class="objects">
      <img class="object_rocket" :src="require('../assets/403/rocket.svg')" width="40px">
      <div class="earth-moon">
          <img class="object_earth" :src="require('../assets/403/earth.svg')" width="100px">
          <img class="object_moon" :src="require('../assets/403/moon.svg')" width="80px">
      </div>
      <div class="box_astronaut">
          <img class="object_astronaut" :src="require('../assets/403/astronaut.svg')" width="140px">
      </div>
    </div>
    <div class="glowing_stars">
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
    </div>
  </div>

</template>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500');

@-moz-keyframes rocket-movement { 100% {-moz-transform: translate(1200px,-600px);} }
@-webkit-keyframes rocket-movement {100% {-webkit-transform: translate(1200px,-600px); } }
@keyframes rocket-movement { 100% {transform: translate(1200px,-600px);} }
@-moz-keyframes spin-earth { 100% { -moz-transform: rotate(-360deg); transition: transform 20s;  } }
@-webkit-keyframes spin-earth { 100% { -webkit-transform: rotate(-360deg); transition: transform 20s;  } }
@keyframes spin-earth{ 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); transition: transform 20s; } }

@-moz-keyframes move-astronaut {
  100% { -moz-transform: translate(-160px, -160px);}
}
@-webkit-keyframes move-astronaut {
  100% { -webkit-transform: translate(-160px, -160px);}
}
@keyframes move-astronaut{
  100% { -webkit-transform: translate(-160px, -160px); transform:translate(-160px, -160px); }
}
@-moz-keyframes rotate-astronaut {
  100% { -moz-transform: rotate(-720deg);}
}
@-webkit-keyframes rotate-astronaut {
  100% { -webkit-transform: rotate(-720deg);}
}
@keyframes rotate-astronaut{
  100% { -webkit-transform: rotate(-720deg); transform:rotate(-720deg); }
}

@-moz-keyframes glow-star {
  40% { -moz-opacity: 0.3;}
  90%,100% { -moz-opacity: 1; -moz-transform: scale(1.2);}
}
@-webkit-keyframes glow-star {
  40% { -webkit-opacity: 0.3;}
  90%,100% { -webkit-opacity: 1; -webkit-transform: scale(1.2);}
}
@keyframes glow-star{
  40% { -webkit-opacity: 0.3; opacity: 0.3;  }
  90%,100% { -webkit-opacity: 1; opacity: 1; -webkit-transform: scale(1.2); transform: scale(1.2); border-radius: 999999px;}
}
.spin-earth-on-hover{
  transition: ease 200s !important;
  transform: rotate(-3600deg) !important;
}
html, body{
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}
.brand-logo{
  margin-left: 25px;
  margin-top: 5px;
  display: inline-block;
}
.central-body{
  padding: 17% 5% 10% 5%;
  text-align: center;
}
.objects img{
    z-index: 90;
    pointer-events: none;
}
.object_rocket{
  z-index: 95;
  position: absolute;
  transform: translateX(-50px);
  top: 75%;
  pointer-events: none;
  animation: rocket-movement 200s linear infinite both running;
}
.object_earth{
  position: absolute;
  top: 20%;
  left: 15%;
  z-index: 90;
  animation: spin-earth 100s infinite linear both;
}
.object_moon{
  position: absolute;
  top: 12%;
  left: 25%;
}
.object_astronaut{
  animation: rotate-astronaut 200s infinite linear both alternate;
}
.box_astronaut{
  z-index: 110 !important;
  position: absolute;
  top: 60%;
  right: 20%;
  will-change: transform;
  animation: move-astronaut 50s infinite linear both alternate;
}
.text-403 {
  position: relative;
  color: #fff;
  font-size: 170px;
  font-weight: bold;
}
.text-lost {
  position: relative;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  word-spacing: .5rem;
}
.image-404{
  position: relative;
  z-index: 100;
  pointer-events: none;
}
.stars{
  background: url('../assets/403/overlay_stars.svg');
  background-color:  #121212 !important;
  background-repeat: repeat;
  background-size: contain;
  background-position: left top;
  height: 100vh;
}
.glowing_stars .star{
  position: absolute;
  border-radius: 100%;
  background-color: #fff;
  width: 3px;
  height: 3px;
  opacity: 0.3;
  will-change: opacity;
}
.glowing_stars .star:nth-child(1){
  top: 80%;
  left: 25%;
  animation: glow-star 2s infinite ease-in-out alternate 1s;
}
.glowing_stars .star:nth-child(2){
  top: 20%;
  left: 40%;
  animation: glow-star 2s infinite ease-in-out alternate 3s;
}
.glowing_stars .star:nth-child(3){
  top: 25%;
  left: 25%;
  animation: glow-star 2s infinite ease-in-out alternate 5s;
}
.glowing_stars .star:nth-child(4){
  top: 75%;
  left: 80%;
  animation: glow-star 2s infinite ease-in-out alternate 7s;
}
.glowing_stars .star:nth-child(5){
  top: 90%;
  left: 50%;
  animation: glow-star 2s infinite ease-in-out alternate 9s;
}
@media only screen and (max-width: 600px){
  .navbar-links{
    display: none;
  }
  
  .custom-navbar{
    text-align: center;
  }
  
  .brand-logo img{
    width: 120px;
  }
  
  .box_astronaut{
    top: 70%;
  }
  
  .central-body{
    padding-top: 25%;
  }
}
</style>
