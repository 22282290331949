<script>
import CoreLogo from './CoreLogo'
export default {
  name: 'CoreToolbar',
  components: {
    CoreLogo
  },
  props: {
    title: {
      type: String,
      default: 'App'
    },
    logo: {
      type: String,
      default: require('../assets/logo_exwexs_black.png')
    },
    clippedLeft: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<template>
  <v-app-bar app :clipped-left="clippedLeft" fixed dense class="elevation-1">
    <slot name="prepend"></slot>
    
      <!-- <router-link :to="{ name: 'home' }"> -->
        <v-toolbar-title class="tertiary--text font-weight-light align-center d-flex">
            <!-- <span class="font-weight-bold display-1 mr-1">EXWEXs</span> -->
            <CoreLogo :width="150" :logo="logo" v-if="logo" />
            <!-- <v-divider vertical class="mx-3" /> -->
            <span class="ml-2 font-weight-thin display-1 text-uppercase">{{ title }}</span>
        </v-toolbar-title>
      <!-- </router-link> -->

    <slot>
      <div class="flex-grow-1"></div>
    </slot>
  </v-app-bar>
</template>

<style scoped>
.logo {
  width: 150px
}
.dark {
  filter: invert(1)
}
</style>