<template>
  <v-row justify="center" align="center" style="height: 100vh">
    <CoreLoginForm @submit="authenticate" :loading="loading" :min-width="400" />
  </v-row>
</template>

<script>
import gql from 'graphql-tag'
import CoreLoginForm from '../components/CoreLoginForm';
import { GET_APP_STATE } from '../store/auth/queries'
import { LOCAL_AUTHENTICATE } from '../store/auth/mutations'

const LOGIN = gql`
mutation ($username: String!, $password: String!) {
  login(
    username: $username,
    password: $password
  )
}`

const ROUTER_HOME = 'home'

export default {
  name: 'CoreLogin',
  components: {
    CoreLoginForm,
  },
  apollo: {
    app: GET_APP_STATE
  },
  data() {
    return {
      app: {}
    }
  },
  mounted() {
    // If user is already loggedIn redirect to nextUrl or 'home'
    if (this.app.isLoggedIn) {
      if (this.$route.query.nextUrl) this.$router.push({ path: this.$route.query.nextUrl }).catch((e) => {
        if (!e.message.includes('Redirected when going from "/login" to')) console.error(e)
      })
      else this.$router.push(ROUTER_HOME).catch(e => {
        if (!e.message.includes('Redirected when going from "/login" to')) console.error(e)
      })
    }
  },
  methods: {
    async authenticate(payload) {
      // Send authentication payload to API
      try {
        const res = await this.$apollo.mutate({
          mutation: LOGIN,
          variables: payload
        })
        if (!(res.data && res.data.login)) throw new Error('Invalid response payload')
        const token = res.data.login

        // Update application local-state
        await this.$apollo.mutate({
          mutation: LOCAL_AUTHENTICATE,
          variables: { token }
        })

        if (this.$route.params.nextUrl) this.$router.push(this.$route.params.nextUrl).catch((e) => {
          if (!e.message.includes('Redirected when going from "/login" to')) console.error(e)
        })
        else this.$router.push({ name: ROUTER_HOME }).catch((e) => {
          if (!e.message.includes('Redirected when going from "/login" to')) console.error(e)
        })
        // FIXME: #SUPERDIRTY
        // eslint-disable-next-line no-self-assign
        // window.location.href = window.location.href
      } catch(err) {
        if (this.$notifier) {
          this.$notifier.error(err)
        }
      }
    }
  },
  computed: {
    loading() {
      return this.$apollo.loading
    }
  }
}
</script>
