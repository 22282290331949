<script>
export default {
  name: 'XChip',
  props: {
    color: String,
    textColor: String,
    outlined: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <v-chip :color="color" :text-color="textColor" :outlined="outlined" :small="small" :x-small="xSmall" :x-large="xLarge">
    <slot></slot>
  </v-chip>
</template>