<script>
export default {
  name: 'CoreUpdateSnackbar',
  data() {
    return {
      show: false
    }
  },
  created () {
    console.log('Listening for swUpdated')
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  methods: {
    showRefreshUI (e) {
      console.log('Update available')
      // Store the ServiceWorkerRegistration instance
      this.registration = e.detail
      this.show = true
    },
    refreshApp () {
      console.log('Refreshing app')
      this.show = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage('skipWaiting')
    },
  },
}
</script>
<template>
  <v-snackbar
    v-model="show"
    :timeout="-1"
    top
  >
    New version available !
    <template v-slot:action="{ attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        @click="refreshApp"
      >
        Refresh
      </v-btn>
    </template>
    <v-spacer />
  </v-snackbar>
</template>