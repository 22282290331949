/* eslint-disable no-console */
import { makeVueCore } from '@exwexs/vue-core'
import VueI18n from 'vue-i18n'
import { defaultDataIdFromObject } from 'apollo-boost'
import Vue from 'vue'
import App from '@/App'
import './registerServiceWorker'
import makeRoutes from '@/makeRoutes'
import './assets/app.sass'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from './fragmentTypes.json'

fetch('/config.json', {
  headers: {
    'content-type': 'application/json',
    accept: 'application/json'
  }
})
  .then(res => res.json())
  .then(config => {
    Vue.prototype.$config = config

    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData
    })

    const { router, vuetify, apolloProvider } = makeVueCore({
      apollo: {
        wsEndpoint: config.wsEndpoint,
        httpEndpoint: config.httpEndpoint,
        fragmentMatcher: fragmentMatcher,
        dataIdFromObject (responseObject) {
          switch (responseObject.__typename) {
            case 'STACItem': return `STACItem:${responseObject.id}:${responseObject.collection}`
            default: return defaultDataIdFromObject(responseObject)
          }
        }
      }
    })

    for (const route of makeRoutes(router)) {
      router.addRoute(route)
    }

    Vue.use(VueI18n)
    const locale = localStorage.getItem('locale') || 'en'
    const i18n = new VueI18n({
      locale,
      fallbackLocale: 'en'
    })

    new Vue({
      i18n,
      router,
      vuetify,
      apolloProvider,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(err => {
    console.log('Initialization failure')
    console.error(err)
  })
