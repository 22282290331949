import Vue from 'vue'
import Vuetify, {
  VDatePicker,
  VTabs,
  VSlider,
  VTextField,
  VListGroup,
  VList,
  VListItem,
  VMenu,
  VImg,
  VNavigationDrawer,
  VAppBar,
  VInput,
  VRow,
  VCol,
  VDialog,
  VExpansionPanel,
  VAlert,
  VItemGroup,
  VIcon,
  VBtn,
  VToolbar,
  VTooltip,
  VProgressLinear,
  VProgressCircular,
  VForm,
  VCard,
  VApp,
  VSheet,
  VSpeedDial,
  VLabel,
  VAvatar,
  VMain,
  VDivider,
  VChip,
  VDataIterator
} from 'vuetify/lib'

import { Ripple, Touch, ClickOutside, Resize, Intersect } from 'vuetify/lib/directives'
import en from 'vuetify/lib/locale/en'
import fr from 'vuetify/lib/locale/fr'

import '@mdi/font/css/materialdesignicons.css'
import './assets/app.scss'
import extend from 'extend'

const DEFAULT_DARK_THEME = {
  primary: '#3e8aed',
  // secondary: '#b0bec5',
  accent: '#FF4081',
  // error: '#b71c1c',
  // background: '#888'
  // toolbar: '#202020'
  // background: '#F5F5F5'
}
const DEFAULT_LIGHT_THEME = {
  primary: '#3f51b5',
  // secondary: '#b0bec5',
  accent: '#FF4081',
  // error: '#b71c1c',
  background: '#f2f5f7'
  // toolbar: '#202020'
  // background: '#F5F5F5'
}

const DEFAULT_THEMES = {
  dark: DEFAULT_DARK_THEME,
  light: DEFAULT_LIGHT_THEME
}

const makeVuetify = (opts = {}) => {
  Vue.use(Vuetify, {
    components: {
      VDatePicker,
      VTabs,
      VSlider,
      VTextField,
      VListGroup,
      VList,
      VListItem,
      VMenu,
      VImg,
      VNavigationDrawer,
      VAppBar,
      VInput,
      VRow,
      VCol,
      VDialog,
      VExpansionPanel,
      VAlert,
      VItemGroup,
      VIcon,
      VBtn,
      VToolbar,
      VTooltip,
      VProgressLinear,
      VProgressCircular,
      VForm,
      VCard,
      VApp,
      VSheet,
      VSpeedDial,
      VLabel,
      VAvatar,
      VMain,
      VDivider,
      VChip,
      VDataIterator
    },
    directives: {
      Ripple,
      Touch,
      ClickOutside,
      Resize,
      Intersect
    }
  })

  const themes = extend(true, DEFAULT_THEMES, opts.themes)

  return new Vuetify({
    lang: {
      locales: { en, fr },
      current: 'en'
    },
    icons: {
      iconfont: opts.iconfont ? opts.iconfont : 'mdi',
    },
    theme: {
      themes,
      dark: true,
      options: {
        customProperties: true,
      }
    }
  })
}

export default makeVuetify