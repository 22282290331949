<script>
export default {
  name: 'XAutocompleteField',
  props: {
    value: [String, Array],
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: 'id'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    label: {
      type: String,
      default: 'label'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    deletableChips: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default() {
        return  []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    internalSearch: {
      get() {
        return this.search
      },
      set(v) {
        if (!v) this.$emit('update:search', '')
        else this.$emit('update:search', v)
      }
    }
  },
  methods: {
    fetchMore(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$emit('fetchMore')
      }
    }
  }
}
</script>

<template>
  <div>
    <v-autocomplete
      v-model="internalValue"
      :items="items"
      :loading="loading"
      :search-input.sync="internalSearch"
      :outlined="outlined"
      :dense="dense"
      :required="required"
      :rules="rules"
      chips
      small-chips
      :deletable-chips="deletableChips"
      :hide-details="hideDetails"
      :item-text="itemText"
      :item-value="itemValue"
      :label="label"
      :multiple="multiple"
      @change="internalSearch=''"
      clearable
      :disabled="disabled"
    >
      <template v-slot:append-item>
        <div v-intersect="fetchMore" />
      </template>
      <template v-slot:selection="{ item, index }">
        <v-chip small v-if="index === 0 || index === 1">{{ item[`${itemText}`] }}</v-chip>
        <span v-if="index === 2" class="grey--text text-caption">(+{{ internalValue.length - 2 }} others)</span>
      </template>
    </v-autocomplete>
  </div>
</template>
