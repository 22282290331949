<script>
export default {
  name: 'STACExplorerItem',
  props: {
    feature: {
      type: Object,
      required: true
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    displayedProperty: {
      type: String,
      default: ''
    }
  },
  methods: {
    toggle(feature) {
      if (!this.locked) {
        this.$emit('toggle', feature)
      }
    }
  },
  computed: {
    displayedPropertyText () {
      if (!this.displayedProperty) {
        return ''
      }
      let propertyValue = this.feature
      for (const subProperty of this.displayedProperty.split('.')) {
        propertyValue = propertyValue[subProperty]
      }

      return `${this.displayedProperty.replace('properties.', '')}: ${propertyValue}`
    }
  }
}
</script>

<template>
  <div
    :key="feature.id"
    :class="{ 'd-flex flex-row align-center pointer': true, 'px-4': multiselect }"
  >
    <template v-if="multiselect">
      <!-- <v-checkbox v-if="selectAll" :input-value="true" disabled /> -->
      <v-checkbox
        :input-value="locked ? true : selected"
        :disabled="locked"
        @click="toggle(feature)"
      />
    </template>
    <div
      class="d-flex flex-row flex-grow-1 align-center pa-2"
      :class="{ 'primary white--text' : !multiselect && selected }"
      @click="toggle(feature)"
    >
      <v-img
        v-if="feature.assets.thumbnail"
        :src="feature.assets.thumbnail.href"
        :alt="feature.id"
        width="50"
        height="50"
        contain
        class="mr-5"
      >
        <template #placeholder>
          <v-row
            class="fill-height fill-width ma-0 grey lighten-2"
            align="center"
            justify="center"
          >
            <v-progress-circular
              width="2"
              size="20"
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
      </v-img>
      <div class="d-flex flex-column flex-grow-1">
        <div style="overflow-wrap: anywhere">
          {{ feature.id }}
        </div>
        <div class="caption"> {{displayedPropertyText}} </div>
      </div>
    </div>
  </div>
</template>
