<script>
import CoreContentToolbar from './CoreContentToolbar'

export default {
  name: 'CoreConfirmDialog',
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String
    },
    error: {
      type: String
    },
    show: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: String,
      default: "300"
    },
    width: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cancelButton: {
      type: Boolean,
      default: true
    },
    plainButtons: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'none',
      validator: value => ['info', 'error', 'warning', 'success', 'none'].includes(value)
    },
    confirmText: {
      type: String,
      default: 'CONFIRM'
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    confirmColor: {
      type: String,
      default: 'success'
    },
    cancelColor: {
      type: String,
      default: 'warning'
    }
  },
  components: {
    CoreContentToolbar
  },
  computed: {
    icon() {
      switch(this.type) {
        case 'warning':
          return 'mdi-alert-circle-outline'
        case 'info':
          return 'mdi-information-outline'
        case 'error':
          return 'mdi-alert-outline'
        case 'success':
          return 'mdi-check-circle-outline'
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="show" persistent :max-width="maxWidth" :width="width" :scrollable="scrollable">
    <v-card>
      <CoreContentToolbar :title="title" />
      <v-divider/>
      <v-card-text v-if="message" class="mt-5" v-html="message">
        <slot></slot>
      </v-card-text>
      <v-card-text class="mt-5" v-else>
        <template v-if="type !== 'none'">
          <v-alert :type="type" outlined class="text-center" :style="`box-shadow: inset 60px 0 var(--v-${type}-base)`">
            <template v-slot:prepend>
              <v-icon color="white">{{ icon }}</v-icon>
            </template>
            <div style="padding-left: 30px">
              <slot></slot>
            </div>
          </v-alert>
        </template>
        <template v-else>
          <slot></slot>
        </template>
        <v-alert :value="!!error" transition="scale-transition" type="error" outlined class="text-center" :style="`box-shadow: inset 60px 0 var(--v-error-base)`">
          <template v-slot:prepend>
            <v-icon color="white">mdi-alert-outline</v-icon>
          </template>
          <div style="padding-left: 30px">
            {{ error }}
          </div>
        </v-alert>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn :outlined="!plainButtons" v-if="cancelButton" :color="cancelColor" @click="$emit('confirm:cancel')" :disabled="loading">Cancel</v-btn>
          <v-btn :outlined="!plainButtons" :color="confirmColor" @click="$emit('confirm:ok')" :loading="loading" :disabled="!!error">{{ confirmText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
