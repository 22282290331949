<script>
import {
  CoreUpdateSnackbar,
  CoreSideMenu,
  CoreNotifier,
  CoreDarkThemeToggle,
  CoreAboutDialog,
  CoreLogoutButton
} from '@exwexs/vue-core'
import meta from '../package.json'
import { GET_APP_STATE } from '@exwexs/vue-core/src/store/auth/queries'

export default {
  name: 'App'  ,
  components: {
    CoreUpdateSnackbar,
    CoreSideMenu,
    CoreNotifier,
    CoreDarkThemeToggle,
    CoreAboutDialog,
    CoreLogoutButton
  },
  data: () => {
    return {
      appName: 'Cosmos UI',
      appVersion: meta.version,
      appDescription: '<div class="text-justify"><p>Backend UI for COSMOS API</p></div>',
      app: {},
      defaultDark: false,
      autoState: true,
      notifications: []
    }
  },
  apollo: {
    app: GET_APP_STATE
  },
  computed: {
    isLogged() {
      return this.app.isLoggedIn && this.$route.name !== 'login'
    },
    isAdmin() {
      return this.app.isLoggedIn && this.app.authLevel === 'ADMIN'
    },
    menu() {
      if (this.isAdmin)
        return [
          { title: 'Users', icon: 'mdi-account-supervisor', to: '/users', color: 'error' },
          { title: 'Asyst', icon: 'mdi-alpha-a-box', to: '/asyst', color: 'blue lighten-2' },
          { title: 'Datum', icon: 'mdi-alpha-d-box', to: '/datum', color: 'blue darken-2' },
          { title: 'Jobs / PEM', icon: 'mdi-alpha-j-box', to: '/jobs', color: 'red' },
          { title: 'Moonseed', icon: 'mdi-alpha-m-box', to: '/moonseed', color: 'warning' },
          { title: 'Pathfinder', icon: 'mdi-alpha-p-box', to: '/pathfinder', color: 'purple darken-1' },
          { title: 'STAC-browser', icon: 'mdi-alpha-s-box', to: '/stac-browser', color: 'lime lighten-1' },
          { title: 'T-Rust', icon: 'mdi-alpha-t-box', to: '/t-rust', color: 'success' },         
          { title: 'Status', icon: 'mdi-bank', to: '/status', color: 'blue-grey darken-1' },
          { title: 'Nrt', icon: 'mdi-alpha-n-box', to: '/nrt', color: 'brown lighten-2' }
        ]
      return []
    },
    overflow() {
      return false
    },
    contentStyle() {
      return this.overflow ? '' : 'max-height: 100vh;overflow-y: auto'
    },
    vueEnv() {
      if (this.$config && this.$config.env) return this.$config.env
      return null
    }
  },
  mounted() {
    this.notifications = this.$notifier.notifications
    if (this.vueEnv === 'production') {
      const elts = document.getElementsByTagName('link')
      for (const elt of elts) {
        if (elt.href.includes('favicon')) elt.href = elt.href.replace('favicon', 'favicon-red')
      }
    }
  }
}
</script>

<template>
  <v-app>
    <CoreUpdateSnackbar />

    <CoreSideMenu v-if="menu" :menu="menu" :autoState="autoState" :vueEnv="vueEnv">
      <template slot="bottom-content">
        <CoreDarkThemeToggle :default="defaultDark" tooltipPosition="right" />
        <CoreAboutDialog :appVersion="appVersion" :appName="appName" :appDescription="appDescription" tooltipPosition="right" v-if="isAdmin" />
        <CoreLogoutButton v-if="(!autoState  || (autoState && app.isLoggedIn))" tooltipPosition="right" />
      </template>
    </CoreSideMenu>
    
    <v-main :style="contentStyle">
      <v-slide-x-transition mode="out-in">
        <router-view app />
      </v-slide-x-transition>
    </v-main>
    
    <CoreNotifier :notifications="notifications" v-if="notifications.length" />
  </v-app>
</template>
