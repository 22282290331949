<script>
export default {
  name: 'XForm',
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  beforeDestroy() {
    this.$refs.form.reset()
    this.$refs.form.resetValidation()
  },
}
</script>

<template>
  <v-form v-model="internalValue" ref="form">
    <slot></slot>
  </v-form>
</template>