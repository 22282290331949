<script>
export default {
  name: 'CoreContentToolbar',
  props: {
    title: String,
    tabs: Array,
    backTo: String,
    loading: Boolean,
    dense: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<template>
<v-toolbar :dense="dense" flat color="transparent">
  <v-btn icon small :to="{name: backTo}" v-if="backTo">
    <v-icon>
      mdi-chevron-left
    </v-icon>
  </v-btn>
  <v-toolbar-title class="tertiary--text font-weight-light text-uppercase">
    <slot>
    {{ title }}
    </slot>
  </v-toolbar-title>
  <v-spacer/>
  <slot name="items-append">
  </slot>
  <v-toolbar-items>
    <slot name="items">
    </slot>
    <v-tabs background-color="transparent" v-if="tabs">
      <v-tab v-for="item of tabs" :key="item.name" :to="item.to">{{ item.name }}<v-icon right>{{ item.icon }}</v-icon></v-tab>
    </v-tabs>
  </v-toolbar-items>
  <v-progress-linear absolute bottom indeterminate :active="loading"></v-progress-linear>
</v-toolbar>
</template>