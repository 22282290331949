<script>
export default {
  name: 'XButton',
  props: {
    label: String,
    color: {
      type: String,
      default: 'primary'
    },
    outlined: {
      type: Boolean,
      default: false
    },
    icon: String,
    iconPosition: {
      type: String,
      default: 'right'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    depressed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <v-btn v-if="iconOnly" :color="color" @click="$emit('click')" icon :disabled="disabled"><v-icon>{{ icon }}</v-icon></v-btn>
  <v-btn v-else :color="color" @click="$emit('click')" :outlined="outlined" :disabled="disabled" :block="block" :small="small" :x-small="xSmall" :x-large="xLarge" :rounded="rounded" :depressed="depressed">
    <v-icon left v-if="icon && iconPosition === 'left'">{{ icon }}</v-icon>
    {{ label }}
    <v-icon right v-if="icon && iconPosition === 'right'">{{ icon }}</v-icon>
  </v-btn>
</template>