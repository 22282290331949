import Vue from 'vue'
import Router from 'vue-router'
import { GET_APP_STATE } from './store/auth/queries'
import checkLevel from './utils/checkLevel'

const makeRouter = (opts = {}) => {
  Vue.use(Router)

  const apolloProvider = opts.apolloProvider.defaultClient
  if (!opts.routes) opts.routes = []

  // TODO : Fusion in makeAuthorizationGuard
  async function authGuard(to, from, next) {
    const appState = await apolloProvider.query({ query: GET_APP_STATE })
    if (!appState.data.app.isLoggedIn) {
      next({
        name: 'login',
        query: { nextUrl: to.fullPath }
      })
    } else next()
  }

  function makeAuthorizationGuard(authLevel, redirectTo = 'home') {
    return async function authorizationGuard(to, from, next) {
      const appState = await apolloProvider.query({ query: GET_APP_STATE })
      if (!appState.data.app.isLoggedIn) {
        next({
          name: 'login',
          query: { nextUrl: to.fullPath }
        })
      } else {
        if (checkLevel(authLevel, appState.data.app.authLevel)) {
          next()
        } else {
          next({
            name: redirectTo
          })
        }
      }
    }
  }

  const router = new Router({
    mode : opts.routerMode || 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/login',
        name: 'login',
        // component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ './components/CoreLogin.vue')
      },
      ...opts.routes
    ]
  })

  router.authGuard = authGuard
  router.makeAuthorizationGuard = makeAuthorizationGuard

  return router
}

export default makeRouter