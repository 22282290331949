<script>
import { CoreConfirmDialog } from '@exwexs/vue-core'
import TableGeneric from '@/components/common/TableGeneric'
import AddGroup from '@/components/user/AddGroup'
import AddEditUser from '@/components/user/AddEditUser'
import AddEditUniverseConfig from '@/components/user/AddEditUniverseConfig'

import USERS_QUERY from '@/graphql/query/user/Users.gql'
import COUNT_USERS_QUERY from '@/graphql/query/user/CountUser.gql'
import GROUPS_QUERY from '@/graphql/query/user/Groups.gql'
import USER_DELETE_MUTATION from '@/graphql/mutation/user/UserDelete.gql'
import UNIVERSE_CONFIGS_QUERY from '@/graphql/query/user/UniverseConfigs.gql'
import COUNT_UNIVERSE_CONFIGS_QUERY from '@/graphql/query/user/CountUniverseConfig.gql'
import UNIVERSE_CONFIG_DELETE_MUTATION from '@/graphql/mutation/user/UniverseConfigDelete.gql'

export default {
  name: 'Users',
  components: {
    TableGeneric,
    AddGroup,
    AddEditUser,
    AddEditUniverseConfig,
    CoreConfirmDialog
  },
  data() {
    return {
      displayOptionsUser: [
        { text: 'Username', value: 'username', align: 'left', sortable: true },
        { text: 'Email', value: 'email', align: 'left', sortable: false },
        { text: 'Level', value: 'authLevel', align: 'center', sortable: true },
        { text: 'Group', value: 'group.name', align: 'center', sortable: false },
        { text: 'Token Expires', value: 'tokenConfig.expiresIn', align: 'center', sortable: false }
      ],
      actionsUser: [
        { tooltip: 'Edit User', icon: 'mdi-pencil', click: this.editUser },
        { tooltip: 'Delete User', icon: 'mdi-delete', click: this.deleteUser }
      ],
      users: [],
      groups: [],
      countUser: 0,
      dialogAddGroup: false,
      dialogAddEditUser: false,
      paginationOptsUser: {
        sortDesc: [false],
        sortBy: ['id']
      },
      userEdited: null,
      userToDelete: null,
      dialogUserDelete: false,
      universeConfigs: [],
      countUniverseConfig: 0,
      dialogAddEditUniverseConfig: false,
      dialogUniverseConfigDelete: false,
      displayOptionsUniverseConfig: [
        { text: 'Modules', value: 'modules', align: 'left', sortable: false, format: 'specificHtml', options: { formatFunction: this.formatModules } },
        { text: 'Users', value: 'users', align: 'left', sortable: false, format: this.formatUsers },
      ],
      actionsUniverseConfig: [
        { tooltip: 'Edit Config', icon: 'mdi-pencil', click: this.editUniverseConfig },
        { tooltip: 'Delete Config', icon: 'mdi-delete', click: this.deleteUniverseConfig }
      ],
      paginationOptsUniverseConfig: {
        sortDesc: [false],
        sortBy: ['id']
      },
      universeConfigEdited: null,
      universeConfigToDelete: null
    }
  },
  apollo: {
    users: {
      query: USERS_QUERY,
      variables() {
        return {
          pagination: {
            page: this.paginationOptsUser.page - 1,
            size: this.paginationOptsUser.itemsPerPage
          },
          order: this.orderUser
        }
      }
    },
    countUser: COUNT_USERS_QUERY,
    groups: GROUPS_QUERY,
    universeConfigs: {
      query: UNIVERSE_CONFIGS_QUERY,
      variables() {
        return {
          pagination: {
            page: this.paginationOptsUniverseConfig.page - 1,
            size: this.paginationOptsUniverseConfig.itemsPerPage
          },
          order: this.orderUniverseConfig
        }
      }
    },
    countUniverseConfig: COUNT_UNIVERSE_CONFIGS_QUERY
  },
  computed: {
    titleUser() {
      return 'User Management'
    },
    orderUser() {
      if (this.paginationOptsUser && this.paginationOptsUser.sortDesc.length > 0 && this.paginationOptsUser.sortBy.length > 0)
        return (this.paginationOptsUser.sortDesc[0] ? '+' : '-') + this.paginationOptsUser.sortBy[0]
      return '-id'
    },
    titleAddEditUser() {
      if (this.userEdited) return 'Edit User'
      return 'New User'
    },
    askDeleteUser() {
      if (this.userToDelete) return `Are you sure you want to delete the user "${this.userToDelete.username}" ?`
      return ''
    },
    titleUniverseConfig() {
      return 'Universe Configuration Management'
    },
    orderUniverseConfig() {
      if (this.paginationOptsUniverseConfig && this.paginationOptsUniverseConfig.sortDesc.length > 0 && this.paginationOptsUniverseConfig.sortBy.length > 0)
        return (this.paginationOptsUniverseConfig.sortDesc[0] ? '+' : '-') + this.paginationOptsUniverseConfig.sortBy[0]
      return '-id'
    },
    titleAddEditUniverseConfig() {
      if (this.universeConfigEdited) return 'Edit Universe Configuration'
      return 'New Universe Configuration'
    },
    askDeleteUniverseConfig() {
      if (this.universeConfigToDelete) return 'Are you sure you want to delete this configuration ?'
      return ''
    },
    isDark() {
      return this.$vuetify.theme.isDark
    }
  },
  methods: {
    editUser(item) {
      this.userEdited = item
      this.dialogAddEditUser = true
    },
    deleteUser(item) {
      this.userToDelete = item
      this.dialogUserDelete = true
    },
    confirmUserDelete() {
      if (this.userToDelete) {
        this.$apollo.mutate({
          mutation: USER_DELETE_MUTATION,
          variables: {
            id: this.userToDelete.id
          }
        }).then(() => {
          this.dialogUserDelete = false
          this.userToDelete = null
          this.$apollo.queries.users.refetch()
          this.$apollo.queries.countUser.refetch()
        }).catch(e => {
          this.$notifier.error(e)
        })
      }
    },
    cancelUserDelete() {
      this.userToDelete = null
      this.dialogUserDelete = false
    },
    closeGroup() {
      this.dialogAddGroup = false
      this.$apollo.queries.groups.refetch()
    },
    closeUser() {
      this.dialogAddEditUser = false
      this.userEdited = null
      this.$apollo.queries.users.refetch()
      this.$apollo.queries.countUser.refetch()
    },

    closeUniverseConfig() {
      this.dialogAddEditUniverseConfig = false
      this.universeConfigEdited = null
      this.$apollo.queries.universeConfigs.refetch()
      this.$apollo.queries.countUniverseConfig.refetch()
    },
    editUniverseConfig(item) {
      this.universeConfigEdited = item
      this.dialogAddEditUniverseConfig = true
    },
    deleteUniverseConfig(item) {
      this.universeConfigToDelete = item
      this.dialogUniverseConfigDelete = true
    },
    confirmUniverseConfigDelete() {
      if (this.universeConfigToDelete) {
        this.$apollo.mutate({
          mutation: UNIVERSE_CONFIG_DELETE_MUTATION,
          variables: {
            id: this.universeConfigToDelete.id
          }
        }).then(() => {
          this.dialogUniverseConfigDelete = false
          this.universeConfigToDelete = null
          this.$apollo.queries.universeConfigs.refetch()
          this.$apollo.queries.countUniverseConfig.refetch()
        }).catch(e => {
          this.$notifier.error(e)
        })
      }
    },
    cancelUniverseConfigDelete() {
      this.universeConfigToDelete = null
      this.dialogUniverseConfigDelete = false
    },
    formatUsers(v) {
      if (v && v.length > 0) {
        return v.reduce((a, c) => {
          if (c) {
            if (a !== '') a += ','
            a += c.username
          }
          return a
        }, '')
      }
      return ''
    },
    formatModules(v) {
      const om = [...v.modules]
      const _om = om.sort(this.sortModule)
      let html = ''
      for (const mod of _om) {
        html += `<div style="display: inline-block; margin-left: 5px; height: 24px; width: 24px; background-color: ${mod.disabled ? 'rgba(255, 255, 255, 0.5)' : mod.logoColor}; color: ${this.isDark ? '#000' : '#FFF'}; font-weight: bold; text-align: center; line-height: 24px; border-radius: 5px;">${mod.name.charAt(0).toUpperCase()}</div>`
      }
      return html
    },
    sortModule(a, b) {
      if (parseInt(a.order) < parseInt(b.order)) return -1
      if (parseInt(a.order) > parseInt(b.order)) return 1
      return 0
    }
  }
}

</script>

<template>
  <div>
    <TableGeneric
      :title="titleUser"
      :displayOptions="displayOptionsUser"
      :items="users"
      :itemsPerPage="10"
      :options.sync="paginationOptsUser"
      :count="countUser"
      :loading="$apollo.queries.users.loading"
      :actions="actionsUser"
    >
      <template slot="management-actions">
        <v-btn color="primary" @click="dialogAddGroup = true" class="mr-2" outlined>
          Add Group
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
        <v-btn color="primary" @click="dialogAddEditUser = true" outlined>
          Add User
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </template>

    </TableGeneric>
    <AddGroup :show="dialogAddGroup" @close="closeGroup" />
    <AddEditUser :show="dialogAddEditUser" :groupList="groups" :userEdited="userEdited" :title="titleAddEditUser" @close="closeUser" />
    <CoreConfirmDialog :show="dialogUserDelete" title="Delete User ?" :message="askDeleteUser" @confirm:ok="confirmUserDelete" @confirm:cancel="cancelUserDelete" max-width="400px"/>

    <TableGeneric
      :title="titleUniverseConfig"
      :displayOptions="displayOptionsUniverseConfig"
      :items="universeConfigs"
      :itemsPerPage="10"
      :options.sync="paginationOptsUniverseConfig"
      :count="countUniverseConfig"
      :loading="$apollo.queries.universeConfigs.loading"
      :actions="actionsUniverseConfig"
    >
      <template slot="management-actions">
        <v-btn color="primary" @click="dialogAddEditUniverseConfig = true" outlined>
          Add Universe Configuration
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </template>

    </TableGeneric>
    <AddEditUniverseConfig :show="dialogAddEditUniverseConfig" :universeConfigEdited="universeConfigEdited" :title="titleAddEditUniverseConfig" @close="closeUniverseConfig" />
    <CoreConfirmDialog :show="dialogUniverseConfigDelete" title="Delete config ?" :message="askDeleteUniverseConfig" @confirm:ok="confirmUniverseConfigDelete" @confirm:cancel="cancelUniverseConfigDelete" max-width="400px"/>
  </div>
</template>
