<script>
import CoreContentToolbar from './CoreContentToolbar.vue'

export default {
  name: 'XCard',
  props: {
    tile: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    title: String,
    minWidth: String,
    maxWidth: String
  },
  components: {
    CoreContentToolbar
  }
}
</script>

<template>
  <v-card :tile="tile" :flat="flat" :min-width="minWidth" :max-width="maxWidth" style="display: flex; flex-direction: column">
    <CoreContentToolbar v-if="title" :title="title" style="flex-grow: 0">
      <template slot="items-append">
        <slot name="title-items-append"></slot>
      </template>
      <template slot="items">
        <slot name="title-items"></slot>
      </template>
    </CoreContentToolbar>
    <v-divider v-if="title" />
    <v-card-text style="flex-grow: 1">
      <slot></slot>
    </v-card-text>
    <v-divider v-if="$slots.actions" />
    <v-card-actions v-if="$slots.actions">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>