import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { InMemoryCache } from 'apollo-boost'
import makeCosmoClient from './plugins/cosmoClient'
import AuthStore from './store/auth'
import extend from 'extend'

const makeApolloProvider = (config = {}) => {
  Vue.use(VueApollo)
  if (!config.store) config.store = []
  config.store.unshift(AuthStore)

  const cache = new InMemoryCache({
    fragmentMatcher: config.fragmentMatcher,
    dataIdFromObject: config.dataIdFromObject
  })
  const store = {
    typeDefs: '',
    resolvers: {},
    cache
  }

  initStore(cache)

  const apolloProvider = makeCosmoClient({
    ...config,
    ...store
  })
  apolloProvider.defaultClient.onResetStore(() => {
    console.log('vue-core: store reset triggered')
    initStore(apolloProvider.defaultClient.cache)
  })
  apolloProvider.defaultClient.onClearStore(() => {
    console.log('vue-core: store clear triggered')
    initStore(apolloProvider.defaultClient.cache)
  })

  return apolloProvider

  function initStore (cache) {
    for (const s of config.store) {
      s.init(cache)
      if (s.typeDefs) store.typeDefs += s.typeDefs
      if (s.resolvers) extend(true, store.resolvers, s.resolvers)
    }
  }
}

export default makeApolloProvider
