<script>

export default {
  name: 'CoreLoginForm',
  props: {
    maxWidth: {
      type: Number,
      default: 600
    },
    minWidth: {
      type: Number,
      default: 600
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      username: '',
      password: '',
      valid: false,
      showPassword: false,
      errorUsernameMessage: '',
      errorPasswordMessage: ''
    }
  },
  watch: {
    username() {
      this.errorUsernameMessage = null
      this.$refs.form.validate()
    },
    password() {
      this.errorPasswordMessage = null
      this.$refs.form.validate()
    }
  },
  methods: {
    submit() {
      this.$emit('submit', {
        username: this.username,
        password: this.password
      })
    }
  }
}
</script>

<template>
  <v-card flat :min-width="minWidth" >
    <v-card-title class="primary white--text">
      <slot name="title">
        <div>
          <div class="headline">{{ $t('signIn') }}</div>
          <div class="caption">{{ $t('caption') }}.</div>
        </div>
      </slot>
    </v-card-title>

    <v-form v-model="valid" @submit.prevent="submit" ref="form">
      <div class="card-content pa-4 pb-5">
        <v-text-field ref="username" v-model="username" :label="$t('username')" autocomplete="username" :error-messages="errorUsernameMessage" required autofocus />
        <v-text-field ref="password" v-model="password" :label="$t('password')" autocomplete="current-password" :type="showPassword ? 'text': 'password'" :error-messages="errorPasswordMessage" :append-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" @click:append="showPassword = !showPassword" required />
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" class="elevation-0" color="primary" type="submit">{{ $t('signIn') }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<i18n locale="fr">
{
  "signIn": "Connexion",
  "caption": "Veuillez vous connecter avec votre compte",
  "username": "Identifiant",
  "password": "Mot de passe"
}
</i18n>

<i18n locale="en">
{
  "signIn": "Sign in",
  "caption": "Please log in with your account.",
  "username": "Username",
  "password": "Password"
}
</i18n>