'use strict'

import VueApollo from 'vue-apollo'
import { ApolloClient, ApolloLink, from, split, HttpLink, Observable } from 'apollo-boost'
// import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

import { print } from 'graphql';
import { createClient } from 'graphql-ws'


const TOKEN_NAME = 'token'

export default function makeCosmoClient (opts = {}) {
  const httpEndpoint = opts.httpEndpoint || process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:3000/graphql'
  const wsEndpoint = opts.wsEndpoint || process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:3000/graphql'

  const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem(TOKEN_NAME)
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    }

    return forward(operation)
  })

  const wsLink = new WebSocketLink({
    url: wsEndpoint,
    reconnect: true,
    lazy: true,
    retryAttempts: 10,
    connectionParams: () => {
      const token = localStorage.getItem(TOKEN_NAME)
      if (token) {
        return {
          Authorization: `Bearer ${token}`
        }
      }
    }
  })

  // const wsLink = new WebSocketLink({
  //   uri: wsEndpoint,
  //   options: {
  //     reconnect: true,
  //     lazy: true,
  //     connectionParams: () => {
  //       const token = localStorage.getItem(TOKEN_NAME)
  //       if (token) {
  //         return {
  //           Authorization: `Bearer ${token}`
  //         }
  //       }
  //     }
  //   }
  // })

  const httpLink = new HttpLink({ uri: httpEndpoint })

  const link = from([
    authLink,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink
    )
  ])

  const apolloClient = new ApolloClient({
    link,
    ...opts
  })

  apolloClient.closeWebSocket = () => {
    wsLink.client.dispose()
  }

  // const { apolloClient, wsClient } = createApolloClient({
  //   ...DEFAULT,
  //   ...opts
  // })
  // apolloClient.wsClient = wsClient

  function defaultErrorHandler (err) {
    console.error(err)
  }

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    },
    errorHandler: opts.errorHandler || defaultErrorHandler
  })

  return apolloProvider
}

class WebSocketLink extends ApolloLink {
  constructor(options) {
    super()
    this.client = createClient(options)
  }

  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err)
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`,
                ),
              )
            }

            return sink.error(
              new Error(
                err
                  .map(({ message }) => message)
                  .join(', '),
              ),
            )
          },
        },
      )
    })
  }
}