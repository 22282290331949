<template>
  <v-app>
    <CoreUpdateSnackbar />
    <CoreToolbar :title="appName" :logo="logo">
      <!-- <template v-slot:prepend v-if="menu && (!autoState  || (autoState && app.isLoggedIn))">
        <v-app-bar-nav-icon @click.stop="showMenu = !showMenu"/>
      </template> -->
      <v-spacer />
      <v-toolbar-items>
        <slot name="toolbar-items-content"></slot>
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-items>
        <slot name="toolbar-items-prepend"></slot>
        <slot name="toolbar-items" v-if="(!autoState  || (autoState && app.isLoggedIn))"></slot>
        <CoreDarkThemeToggle :default="defaultDark" />
        <CoreAboutDialog :appVersion="appVersion" :appName="appName" :appDescription="appDescription" :logo="logo" />
        <slot name="toolbar-items-append" v-if="(!autoState  || (autoState && app.isLoggedIn))"></slot>
        <CoreLogoutButton v-if="(!autoState  || (autoState && app.isLoggedIn))" />
      </v-toolbar-items>
    </CoreToolbar>

    <CoreSideMenu v-if="menu" :menu="menu" :autoState="autoState" />
    
    <slot>
    </slot>

    <v-main :style="contentStyle">
      <slot name="content" >
        <v-slide-x-transition mode="out-in">
          <router-view app/>
        </v-slide-x-transition>
      </slot>
    </v-main>
    
    <CoreNotifier :notifications="notifications"/>
  </v-app>
</template>

<script>
import CoreUpdateSnackbar from '../components/CoreUpdateSnackbar'
import CoreToolbar from '../components/CoreToolbar'
import CoreAboutDialog from '../components/CoreAboutDialog'
import CoreLogoutButton from '../components/CoreLogoutButton'
import CoreDarkThemeToggle from '../components/CoreDarkThemeToggle'
import CoreSideMenu from '../components/CoreSideMenu'
import CoreNotifier from '../components/CoreNotifier'

import { GET_APP_STATE } from '../store/auth/queries'

export default {
  name: 'CoreLayout',
  props: {
    autoState: {
      type: Boolean,
      default: true
    },
    appName: {
      type: String,
      default: 'APPNAME'
    },
    appVersion: {
      type: String,
      default: 'X.Y.Z'
    },
    appDescription: {
      type: String,
      default: 'APP DESCRIPTION'
    },
    menu: {
      type: Array,
      default: null
    },
    defaultDark: {
      type: Boolean,
      default: false
    },
    overflow: {
      type: Boolean,
      default: true
    },
    logo: {
      type: String
    }
  },
  components: {
    CoreToolbar,
    CoreLogoutButton,
    CoreSideMenu,
    CoreAboutDialog,
    CoreDarkThemeToggle,
    CoreUpdateSnackbar,
    // CoreInfoDialog,
    CoreNotifier
  },
  apollo: {
    app: GET_APP_STATE
  },
  data() {
    return {
      info: false,
      notifications: this.$notifier.notifications,
      app: {}
    }
  },
  computed: {
    contentStyle() {
      return this.overflow ? '' : 'max-height: 100vh;'
    }
  }
};
</script>

<style lang="scss">
.theme--dark {
  .border {
    border : 1px solid rgba(255, 255, 255, 0.12);
  }
}

.theme--light {
  .border {
    border : 1px solid rgba(0, 0, 0, 0.12);
  }
}
</style>