<script>
import { CoreContentToolbar } from '@exwexs/vue-core'

import USER_CREATE_MUTATION from '@/graphql/mutation/user/UserCreate.gql'
import USER_UPDATE_MUTATION from '@/graphql/mutation/user/UserUpdate.gql'

export default {
  name: 'AddEditUser',
  components: {
    CoreContentToolbar
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    },
    userEdited: {
      type: Object,
      default: () => {
        return null
      }
    },
    title: {
      type: String,
      default: 'New User'
    }
  },
  data() {
    return {
      valid: true,
      username: '',
      password: '',
      showPassword: false,
      email: '',
      authLevel: 'USER',
      authLevels: ['VISITOR', 'USER', 'SERVICE', 'ADMIN'],
      groupId: null,
      tokenExpiresIn: '30d',
      roles: [],
      rolesItems: [],
      roleSearch: null,
      rules: {
        required: v => !!v || 'Required.',
        counter: v => v.length <= 100 || 'Max 100 characters.',
        min: v => v.length >= 8 || 'Min 8 characters',
        email: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Invalid e-mail.'
        },
        role: v => !!v
      }
    }
  },
  computed: {
    internalShow: {
      get() {
        return this.show
      },
      set (v) {
        this.$emit('update:show', v)
      }
    }
  },
  methods: {
    removeRole(item) {
      if (this.roles.includes(item)) this.roles = this.roles.filter(e => e !== item)
    },
    closeUser() {
      this.username = ''
      this.password = ''
      this.email = ''
      this.roles = []
      this.groupId = null
      this.tokenExpiresIn = '30d'
      this.authLevel = 'USER'
      this.$emit('close')
    },
    async saveUser() {
      if (this.$refs.form.validate()) {
        if (this.userEdited) {
          await this.updateUser(this.userEdited.id, {
            username: this.username,
            email: this.email,
            authLevel: this.authLevel,
            roles: this.roles.map(r => r.text),
            groupId: this.groupId ? this.groupId: undefined,
            tokenConfig: {
              expiresIn: this.tokenExpiresIn
            }
          })
        } else {
          await this.createUser({
            username: this.username,
            email: this.email,
            authLevel: this.authLevel,
            groupId: this.groupId ? this.groupId: undefined,
            password: this.password,
            roles: this.roles.map(r => r.text),
            tokenConfig: {
              expiresIn: this.tokenExpiresIn
            }
          })
        }
        this.closeUser()
      }
    },
    async createUser(user) {
      try {
        const res = await this.$apollo.mutate({
          mutation: USER_CREATE_MUTATION,
          variables: {
            inputs: { ...user }
          }
        })
        const { data, errors } = res
        if (data.createUser.id) {
          return data.createUser
        } else {
          this.$notifier.error(errors)
        }
      } catch (err) {
        this.$notifier.error(err)
        throw err
      }
    },
    async updateUser(id, user) {
      try {
        const res = await this.$apollo.mutate({
          mutation: USER_UPDATE_MUTATION,
          variables: {
            id,
            modifications: { ...user }
          }
        })
        const { data, errors } = res
        if (data.updateUser.id) {
          return data.updateUser
        } else {
          this.$notifier.error(errors)
        }
      } catch (err) {
        this.$notifier.error(err)
        throw err
      }
    }
  },
  watch: {
    roles (val, prev) {
      if (val.length === prev.length) return

      this.roles = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            // color: this.colors[this.nonce - 1],
          }

          this.rolesItems.push(v)

          // this.nonce++
        }

        return v
      })
    },
    userEdited(newValue) {
      if (newValue) {
        this.username = newValue.username
        this.email = newValue.email
        this.roles = newValue.roles
        this.authLevel = newValue.authLevel
        this.groupId = newValue.group ? newValue.group.id : undefined
        this.tokenExpiresIn = newValue.tokenConfig ? newValue.tokenConfig.expiresIn : '30d'
      } else {
        this.username = ''
        this.email = ''
        this.roles = []
        this.authLevel = 'USER'
        this.groupId = undefined
        this.tokenExpiresIn = '30d'
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="internalShow" max-width="700px" persistent>
    <v-card>
      <CoreContentToolbar :title="title" />
      <v-divider />
      <v-form ref="form" v-model="valid" @submit.prevent="saveUser">
        <v-card-text>
          <v-row dense>
            <v-col cols="6">
              <v-text-field dense outlined label="Username" v-model="username" :rules="[rules.required, rules.counter]" counter maxLength="100" required v-if="show" />
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined label="Email" v-model="email" :rules="[rules.required, rules.email]" required />
            </v-col>
            <v-col cols="6" v-if="!userEdited">
              <v-text-field dense outlined label="Password" v-model="password" :rules="[rules.required, rules.min]" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" counter hint="At least 8 characters" @click:append="showPassword = !showPassword" required />
            </v-col>
            <v-col cols="6">
              <v-select dense outlined v-model="authLevel" :items="authLevels" label="Auth Level" menu-props="offset-y" />
            </v-col>
            <v-col cols="6">
              <v-select dense outlined v-model="groupId" :items="groupList" item-text="name" item-value="id" label="Group" menu-props="offset-y" />
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined label="Token Expires In" v-model="tokenExpiresIn" :rules="[rules.required]" required />
            </v-col>
          </v-row>
          <v-row dense>
             <v-combobox
              label="Roles"
              v-model="roles"
              :hide-no-data="!roleSearch"
              :items="rolesItems"
              :search-input.sync="roleSearch"
              hide-selected
              multiple
              small-chips
              outlined
              dense>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :color="`${item.color} lighten-3`"
                  :input-value="selected"
                  label
                  small
                >
                  <span class="pr-2">
                    {{ item.text }}
                  </span>
                  <v-icon
                    small
                    @click="parent.selectItem(item)"
                  >
                    $delete
                  </v-icon>
                </v-chip>
              </template>
              <!-- <template v-slot:item="{ index, item }">
                <v-text-field
                  v-if="editing === item"
                  v-model="editing.text"
                  autofocus
                  flat
                  background-color="transparent"
                  hide-details
                  solo
                  @keyup.enter="edit(index, item)"
                ></v-text-field>
                <v-chip
                  v-else
                  :color="`${item.color} lighten-3`"
                  dark
                  label
                  small
                >
                  {{ item.text }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-list-item-action @click.stop>
                  <v-btn
                    icon
                    @click.stop.prevent="edit(index, item)"
                  >
                    <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template> -->
              </v-combobox>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn outlined color="red darken-2" @click="closeUser">Cancel</v-btn>
          <v-btn outlined color="green darken-2" :disabled="!valid" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>