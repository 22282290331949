<script>
export default {
  name: 'CoreBlockLine',
  props: {
    label: {
      type: String
    },
    col1: {
      type: Number,
      default: 5
    },
    col2: {
      type: Number,
      default: 7
    },
    padding: {
      type: String,
      default: 'pa-0'
    }
  }
}
</script>

<template>
  <v-container class="pa-0">
    <v-row no-gutters :class="padding">
      <v-col :cols="col1" class="pa-1">
        <div class="font-weight-bold text-left">{{ label }}</div>
      </v-col>
      <v-col :cols="col2" class="text-left pa-1">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>