<script>
export default {
  name: 'XDivider',
  props: {
    vertical: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <v-divider :vertical="vertical" />
</template>