<script>
export default {
  name: 'CoreNotifierAlert',
  props: {
    message: { type: String, default: '' },
    type: { type: String, default: 'info' },
    text_detail: { type: String, default: 'Details' }
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    color() {
      if (['info', 'success', 'warning', 'error'].includes(this.type)) return this.type
      return 'info'
    },
    icon() {
      switch (this.type) {
        case 'info':
          return 'mdi-information'
        case 'success':
          return 'mdi-check-circle'
        case 'warning':
          return 'mdi-shield-alert'
        case 'error':
          return 'mdi-alert'
        default:
          return 'mdi-information'
      }
    }
  },
  methods: {
    onChange() {
      if (this.show === false) {
        this.$emit('close')
      }
    }
  }
}
</script>

<template>
  <v-alert dismissible class="cc-alert elevation-8" v-model="show" :color="color" :icon="icon" @input="onChange" transition="fade-transition">
    <div class="font-weight-bold white--text">
      <slot></slot>
    </div>

    <v-expansion-panel v-if="$slots.details" class="details">
      <v-expansion-panel-content>
        <div slot="header">{{ text_detail }}</div>
        <slot name="details"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-alert>
</template>

<style lang="sass">
  .cc-alert
    font-size: 1.15rem
    .details
      margin-top: 10px
      box-shadow: none
      .v-expansion-panel__container
        background-color: transparent !important
      .v-expansion-panel__header
        padding: 0
        & > div
          &:not(.header__icon)
            flex-grow: 0
          &.header__icon
            margin-left: 5px
</style>
