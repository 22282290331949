<script>
export default {
  name: 'STACExplorerAssets',
  props: {
    assets: {
      type: Array,
      required: true
    }
  }
}
</script>

<template>
<!-- TODO -->
  <div class="d-flex flex-column">
    <div v-for="asset in assets" :key="asset.title">
      {{ asset }}
    </div>
  </div>
</template>
