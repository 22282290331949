import { render, staticRenderFns } from "./CoreLoginForm.vue?vue&type=template&id=b8bd8392&"
import script from "./CoreLoginForm.vue?vue&type=script&lang=js&"
export * from "./CoreLoginForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_fda74d0dd233d5440f16000c160c219e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CoreLoginForm.vue?vue&type=custom&index=0&blockType=i18n&locale=fr"
if (typeof block0 === 'function') block0(component)
import block1 from "./CoreLoginForm.vue?vue&type=custom&index=1&blockType=i18n&locale=en"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_be6ad1899880d627e43945eca176d74c/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VDivider,VForm,VSpacer,VTextField})
