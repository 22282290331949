export default function Token(_raw) {
  const parseJwt = token => {
    const chunks = token.split('.')
    chunks.pop()
    return chunks.map(chunk => JSON.parse(atob(chunk))).reduce((result, current) => Object.assign(result, current))
  }

  const parsedToken = parseJwt(_raw)
  const username = parsedToken.username
  const authLevel = parsedToken.authLevel
  const exp = parsedToken.exp
  const iss = parsedToken.iss
  const alg = parsedToken.alg

  this.getUsername = () => username
  this.getAuthLevel = () => authLevel
  this.getRaw = () => _raw

  this.isValid = () => {
    if (exp > Math.round(Date.now() / 1000) && iss && alg && alg != 'none') return true
    return false
  }

  // This can't work (this.$apollo)
  // this.remoteValidation = async () => {
  //   try {
  //     const ME = gql`query {
  //       me {
  //         id
  //         username
  //         email
  //         authLevel
  //       }
  //     }`
  //     await this.$apollo.query({
  //       query: ME
  //     })
  //     return true
  //   } catch (e) {
  //     console.error(e)
  //     return false
  //   }
  // }
}